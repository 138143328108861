import classes from "./InvitatieNuntaBotez1.module.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../store/modal-slice";
import dayjs from "dayjs";
import Slide1 from "./Slides/Slide1";
import Slide2 from "./Slides/Slide2";
import Slide3 from "./Slides/Slide3";
import Scroll from "../../Components/Scroll/Scroll";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Url from "../../Components/Url/Url";
import Pachete from "../../Components/Pachete/Pachete";
import Auth from "../../../../Components/Auth/Auth";
import Cookies from 'js-cookie';

const lastSaturday = (demoNunta) => {
    const t =
        new Date(demoNunta).getDate() + (6 - new Date(demoNunta).getDay()) - 7;
    const lastSaturday = new Date(demoNunta);
    lastSaturday.setDate(t);
    return lastSaturday;
};
const saturdayIn3Months = lastSaturday(
    new Date().setMonth(new Date().getMonth() + 3)
);

const InvitatieNuntaBotez1 = (props) => {
    const { invId, data, isLoggedIn, live, urlData, editMode } = props;

    const [eventDate, setEventDate] = useState(dayjs(saturdayIn3Months));
    const checkoutModal = Cookies.get("checkout_step") || "link";
    const [slide1Updated, setSlide1Updated] = useState(
        props.data?.nb1s1 ?? !!Cookies.get("nb1s1")
    );
    const [slide2Updated, setSlide2Updated] = useState(
        props.data?.nb1s2 ?? !!Cookies.get("nb1s2")
    );

    // If we are on a live invitation or editing one
    (data?.nb1s1 && (live || (editMode && !Cookies.get(`${invId}_nb1s1`))))
        && Cookies.set(`${invId}_nb1s1`, JSON.stringify(data?.nb1s1), 1);
    (data?.nb1s2 && (live || (editMode && !Cookies.get(`${invId}_nb1s2`))))
        && Cookies.set(`${invId}_nb1s2`, JSON.stringify(data?.nb1s2), 1);
    (data?.nb1s3 && (live || (editMode && !Cookies.get(`${invId}_nb1s3`))))
        && Cookies.set(`${invId}_nb1s3`, JSON.stringify(data?.nb1s3), 1);

    const nb1Data = {
        nb1s1: Cookies.get("nb1s1") && JSON.parse(Cookies.get("nb1s1")),
        nb1s2: Cookies.get("nb1s2") && JSON.parse(Cookies.get("nb1s2")),
        nb1s3: Cookies.get("nb1s2") && Cookies.get("nb1s3")
            ? Cookies.get("nb1s3") && JSON.parse(Cookies.get("nb1s3"))
            : {
                image: {
                    value: "nb1s3_1",
                    url: "/Media/Videos/Modele/Nunta/nb1s3_1",
                },
                copii: true,
                cazare: true,
                observatii: true,
            }
    };

    const handleEventDate = (date) => {
        setEventDate(date);
    };

    const dispatch = useDispatch();

    const openModalUrl = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle:
                    checkoutModal === "link"
                        ? "Alege link-ul invitației"
                        : "Alege pachetul dorit",
                modalContent:
                    checkoutModal === "link" ? (
                        <Url
                            data={nb1Data}
                            type='nb1'
                        />
                    ) : (
                        <Pachete
                            newInv={true}
                            data={nb1Data}
                            type='nb1'
                        />
                    ),
            })
        );
    };

    const openSignUpModal = () => {
        dispatch(
          modalActions.modal({
            showModal: true,
            modalTitle: "Intră în cont",
            modalContent: <Auth
                fromInvitation={true}
                data={nb1Data}
                invitationType='nb1'
                openModalUrl={saveDataHandler}
            />,
          })
        );
      };

    const saveDataHandler = () => {
        const ul = localStorage.getItem("ul");
        if (isLoggedIn || ul) {
            openModalUrl();
        } else {
            openSignUpModal();
        }
    };

    return (
        <div className={classes["invitatie-nunta-botez-1"]}>
            {!live && <Header editMode />}
            <div id={classes.slide_1_wrapper} className={classes.section}>
                <Slide1
                    uid={isLoggedIn}
                    invId={invId}
                    invTitle={props.invTitle}
                    setEventDate={handleEventDate}
                    slideUpdated={setSlide1Updated}
                    live={live}
                    editMode={editMode}
                />
                <div className={classes['scroll-wrapper-desktop']}>
                    <Scroll scrollTo={classes.slide_2_wrapper} />
                </div>
            </div>
            <div id={classes.slide_2_wrapper} className={classes.section}>
                <Slide2
                    uid={isLoggedIn}
                    invId={invId}
                    invTitle={props.invTitle}
                    slideUpdated={setSlide2Updated}
                    live={live}
                    editMode={editMode}
                />
                <div className={classes['scroll-wrapper-desktop']}>
                    <Scroll scrollTo={classes.slide_3_wrapper} />
                </div>
            </div>
            <div id={classes.slide_3_wrapper} className={classes.section}>
                <Slide3
                    uid={isLoggedIn}
                    invId={invId}
                    invTitle={props.invTitle}
                    eventDate={eventDate}
                    live={live}
                    urlData={urlData}
                    editMode={editMode}
                />
            </div>
            {!live && !editMode && (
                <Footer
                    slide1Updated={slide1Updated}
                    slide2Updated={slide2Updated}
                    saveDataHandler={saveDataHandler}
                />
            )}
        </div>
    );
};

export default InvitatieNuntaBotez1;
