import classes from "./Countdown.module.scss";

import { useState, useEffect } from "react";
import { dateToRo } from "../../../../Helpers/Helpers";

const Countdown = (props) => {
  const ONE_DAY = 86400000;
  const { color, date, type, eventHour } = props;
  const [daysPassed, setDaysPassed] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

      //I'm adding this section so I don't have to keep updating this pen every year :-)
      //remove this if you don't need it
      let today = new Date(),
        dd = String(today.getDate()).padStart(2, "0"),
        mm = String(today.getMonth() + 1).padStart(2, "0"),
        yyyy = today.getFullYear(),
        // nextYear = yyyy + 1,
        dayMonth = date.split("/")[0] + "/" + date.split("/")[1] + "/",
        year = date.split("/")[2],
        theday = dayMonth + year;

      today = mm + "/" + dd + "/" + yyyy;

      const now = new Date().getTime(),
        countDown = new Date(theday).getTime(),
        distance = countDown - now;

      setTimeLeft(distance);

      if (distance / day < 0) {
        setDaysPassed(Math.abs(Math.trunc(distance / day)));
      }
      // Populate DAYS
      let daysElements = document.getElementById("countdownDays");
      daysElements.innerText = Math.floor(distance / day);
      // Populate HOURS
      let hoursElements = document.getElementById("countdownHours");
      hoursElements.innerText = Math.floor((distance % day) / hour);
      // Populate MINUTES
      let minutesElements = document.getElementById("countdownMinutes");
      minutesElements.innerText = Math.floor((distance % hour) / minute);
      // Populate SECONDS
      let secondsElements = document.getElementById("countdownSeconds");
      secondsElements.innerText = Math.floor((distance % minute) / second);

      if (distance < 0) {
        document.getElementById("countdown").style.display = "none";
        clearInterval(intervalId);
      }
      if (distance > -ONE_DAY && distance < 0) {
        document.getElementById("today").style.display = "block";
        document.getElementById("passed").style.display = "none";
        clearInterval(intervalId);
      }
      if (distance < -ONE_DAY) {
        document.getElementById("passed").style.display = "block";
        document.getElementById("today").style.display = "none";
        clearInterval(intervalId);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft, date]);

  return (
    <div
      className={classes["countdown-container"]}
      style={{ color: color }}
    >
      <div id='countdown' className={classes.countdown}>
        {type === 'nunta' && <p>Nunta noastră va avea loc în data de</p>}
        {type === 'botez' && <p>Botezul va avea loc în data de</p>}
        {type !== 'nunta-botez' && <h2>{dateToRo(date)}</h2>}
        {['aniversare', 'corporate'].includes(type) && <p>Evenimentul va avea loc în data de</p>}
        <p>Au mai rămas:</p>
        <ul>
          <li>
            <span id='countdownDays'></span>Zile
          </li>
          <li>
            <span id='countdownHours'></span>Ore
          </li>
          <li>
            <span id='countdownMinutes'></span>Minute
          </li>
          <li>
            <span id='countdownSeconds'></span>Secunde
          </li>
        </ul>
      </div>
      <div id='today' className={classes.today}>
        <h2 className={classes.wedding}>
          {type === 'nunta' && 'Nunta are loc azi!!! '}
          {type === 'botez' && 'Botezul are loc azi!!! '}
          {type === 'nunta-botez' && 'Nunta și botezul au loc azi!!! '}
          {['aniversare', 'corporate'].includes(type) && `Evenimentul are loc azi începând cu ora ${eventHour.getHours()}:${eventHour.getMinutes().toString().length === 1 ? '0' + eventHour.getMinutes() : eventHour.getMinutes()}!!! `}
          <span>
            <i className='fas fa-glass-cheers'></i>
          </span>
        </h2>
      </div>
      <div id='passed' className={classes.passed}>
        <h2 className={classes.wedding}>
          {type === 'nunta' && `Nunta noastră a avut loc acum ${daysPassed} zile! `}
          {type === 'botez' && `Botezul a avut loc acum ${daysPassed} zile! `}
          {type === 'nunta-botez' && `Nunta și botezul au avut loc acum ${daysPassed} zile! `}
          {['aniversare', 'corporate'].includes(type) && `Evenimentul a avut loc acum ${daysPassed} zile! `}
          <span>
            <i className='fas fa-glass-cheers'></i>
          </span>
        </h2>
      </div>
    </div>
  );
};

export default Countdown;
