import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import FormControl from "@mui/material/FormControl";

const TimePickerInput = (props) => {
  const hourChangeHandler = (event) => {
    props.changeHandler(Date.parse(event?.$d));
  };
  return (
    <FormControl fullWidth={!props.notFullWidth} component='fieldset'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label={props.label}
          value={props.value}
          onChange={hourChangeHandler}
          ampm={false}
          disabled={props.disabled}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default TimePickerInput;
