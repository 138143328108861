import classes from "../../../Form1.module.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import Spinner from "../../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../../Components/UI/AlertMessage/AlertMessage";
import ImageRadios from "../../../Components/ImageRadios/ImageRadios";
import CheckBox from "../../../../../Components/UI/Form/CheckBox";
import { saveOrUpdateInvitationData } from "../../../../../firebase-invitatii";
import { errorHandler } from "../../../../../Helpers/Helpers";
import Cookies from "js-cookie";

const FormSlide3 = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState({});
    const [slideImage, setSlideImage] = useState(props.slideData.image);

    const dispatch = useDispatch();

    const locallySaveSlide3 = (labelName, dataSlide3, hoursToExpire) => {
        Cookies.set(labelName, JSON.stringify(dataSlide3), { expires: 1 / 24 * hoursToExpire });
    };

    const onParticipantiChange = (showParticipanti) => {
        formik.setFieldValue("showParticipanti", showParticipanti, !!formik.touched.showParticipanti);
    };

    const onVideoChange = (video) => {
        formik.setFieldValue("video", video.value, !!formik.touched.video);
        setSlideImage(video);
    };

    const invitatiileMeleHandler = () => {
        window.location.pathname = '/invitatiile-mele';
    }

    const formik = useFormik({
        initialValues: {
            image: props.slideData.image,
            showParticipanti: props.slideData.showParticipanti,
        },
        validationSchema: Yup.object().shape({
            image: Yup.object(),
            showParticipanti: Yup.bool(),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            setIsSaving(true);
            const slide3Data = {
                image: {
                    value: slideImage.value,
                    url: slideImage.url
                        ? slideImage.url
                        : `/Media/Images/Modele/Aniversare/${slideImage.value}`,
                },
                showParticipanti: values.showParticipanti,
            };
            props.setSlideData(slide3Data);
            if (props.editMode) {
                const invData = {
                    a1s1: JSON.parse(Cookies.get(`${props.invId}_a1s1`)),
                    a1s2: JSON.parse(Cookies.get(`${props.invId}_a1s2`)),
                    a1s3: JSON.parse(Cookies.get(`${props.invId}_a1s3`)),
                };
                locallySaveSlide3(`${invData.a1s1.iid}_a1s3`, slide3Data, 1);
                invData.a1s3 = Cookies.get(`${invData.a1s1.iid}_a1s3`);
                await saveOrUpdateInvitationData(
                    props.uid,
                    'a1',
                    false,
                    invData,
                    false,
                    props.invTitle
                    )
                    .then(() => {
                        setIsSaving(false);
                        setAlert({
                            severity: "success",
                            title: "Modificări salvate",
                            description: "Modificările au fost salvate cu succes!",
                        });
                        setSuccess(true);
                    })
                    .catch((error) => {
                        setError(true);
                        setIsSaving(false);
                        setAlert(errorHandler(error));
                    });
            } else {
                locallySaveSlide3('a1s3', slide3Data, 24);
                setIsSaving(false);
                setAlert({
                    severity: "success",
                    title: "Modificări salvate",
                    description: `Modificările au fost salvate cu succes!`,
                });
                setSuccess(true);
                setTimeout(() => {
                    dispatch(modalActions.close());
                }, 750);
            }
        },
    });

    return (
        <div className={classes["form-wrapper"]}>
            {isSaving && <Spinner text='Salvare...' />}
            {error && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <>
                    <AlertMessage
                        severity={alert.severity}
                        title={alert.title}
                        description={alert.description}
                        refresh={alert.refresh}
                    />
                    {props.editMode && (
                        <Button
                            id={classes.submitButton}
                            type='button'
                            variant='contained'
                            onClick={invitatiileMeleHandler}
                        >
                            Înapoi la "Invitațiile mele"
                        </Button>
                    )}
                </>
            )}
            {!isSaving && !success && !error && (
                <form onSubmit={formik.handleSubmit}>
                    <p>
                        În funcție de evenimentul dumneavoastră activați sau
                        dezactivați secțiunea ”Număr participanți”.
                    </p>
                    <CheckBox
                        label='Activează / dezactivează secțiunea ”Număr participanți”'
                        value={formik.values.showParticipanti}
                        changeHandler={onParticipantiChange}
                    />
                    <ImageRadios
                        default={props.slideData.image}
                        text='Video dreapta, vizibil doar pe ecrane mari (PC):'
                        labels={[
                            "a1s3_1",
                            "a1s3_2",
                            "a1s3_3",
                            "a1s3_4",
                            "a1s3_5",
                            "a1s3_6",
                            "a1s3_7",
                            "a1s3_8",
                            "a1s3_9",
                            "a1s3_10",
                            "a1s3_11",
                            "a1s3_12",
                            "a1s3_13",
                            "a1s3_14",
                            "a1s3_15",
                            "a1s3_16",
                            "a1s3_17",
                            "a1s3_18",
                            "a1s3_19",
                        ]}
                        path='Aniversare'
                        changeHandler={onVideoChange}
                    />
                    <Button
                        id={classes.submitButton}
                        type='submit'
                        variant='contained'
                    >
                        Actualizare
                    </Button>
                </form>
            )}
        </div>
    );
};

export default FormSlide3;
