import { functions } from "./firebase";
import { httpsCallable } from "firebase/functions";

// import { doc, getDoc, updateDoc, } from "firebase/firestore";

// Give admin rights
export const giveAdminRights = async (email, rights) => {
    const addAdminRole = httpsCallable(functions, 'addAdminRole'),
        res = await addAdminRole({ email, rights });
    return res.data;
};

// Find invitation data
export const findInvitationData = async (iid) => {
    const findInvitationData = httpsCallable(functions, 'findInvitationData'),
        res = await findInvitationData({ iid });
    return res.data;
};

// Delete invitation
export const deleteInvitation = async (iid) => {
    const deleteInvitation = httpsCallable(functions, 'deleteInvitation'),
        res = await deleteInvitation({ iid });
    return res.data;
}
