import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchInvitationData } from "../../../../../../../firebase-invitatii";
import { errorHandler } from "../../../../../../../Helpers/Helpers";
import Spinner from "../../../../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../../../../Components/UI/AlertMessage/AlertMessage";
import InvitatieNunta1 from "../../../../../../Invitatii/Nunta/InvitatieNunta1/InvitatieNunta1";
import InvitatieBotez1 from "../../../../../../Invitatii/Botez/InvitatieBotez1/InvitatieBotez1";
import InvitatieNuntaBotez1 from "../../../../../../Invitatii/NuntaBotez/InvitatieNuntaBotez1/InvitatieNuntaBotez1";
import InvitatieAniversare1 from "../../../../../../Invitatii/Aniversare/InvitatieAniversare1/InvitatieAniversare1";
import InvitatieCorporate1 from "../../../../../../Invitatii/Corporate/InvitatieCorporate1/InvitatieCorporate1";

const Edit = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [alertError, setAlertError] = useState(false);
    const [alert, setAlert] = useState({});
    const [invData, setInvData] = useState(null);
    const { iid, invType } = useParams();
    const uid = useSelector((state) => state.auth.uid);

    useEffect(() => {
        const fetchInvitation = async () => {
            await fetchInvitationData(iid, invType, uid)
            .then((response) => {
                setInvData(response);
                setIsLoading(false);
            })
            .catch((err) => {
                setAlertError(true);
                setAlert(errorHandler(err));
                setIsLoading(false);
            })
        }
        uid && fetchInvitation();
    }, [iid, invType, uid]);

    return (
        <>
            {isLoading && <Spinner text='Se încarcă pagina...' />}
            {alertError && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {!isLoading && !alertError && (
                <>
                    {invData && ['n1'].includes(invType) && (
                        <InvitatieNunta1
                            invId={iid}
                            data={{
                                n1s1: invData[`${invType}s1`],
                                n1s2: invData[`${invType}s2`],
                                n1s3: invData[`${invType}s3`],
                            }}
                            isLoggedIn={uid}
                            editMode={true}
                            urlData={{
                                uid,
                                invitationId: iid
                            }}
                            invTitle={invData.title}
                        />
                    )}
                    {invData && ['b1'].includes(invType) && (
                        <InvitatieBotez1
                            invId={iid}
                            data={{
                                b1s1: invData[`${invType}s1`],
                                b1s2: invData[`${invType}s2`],
                                b1s3: invData[`${invType}s3`],
                            }}
                            isLoggedIn={uid}
                            editMode={true}
                            urlData={{
                                uid,
                                invitationId: iid
                            }}
                            invTitle={invData.title}
                        />
                    )}
                    {invData && ['nb1'].includes(invType) && (
                        <InvitatieNuntaBotez1
                            invId={iid}
                            data={{
                                nb1s1: invData[`${invType}s1`],
                                nb1s2: invData[`${invType}s2`],
                                nb1s3: invData[`${invType}s3`],
                            }}
                            isLoggedIn={uid}
                            editMode={true}
                            urlData={{
                                uid,
                                invitationId: iid
                            }}
                            invTitle={invData.title}
                        />
                    )}
                    {invData && ['a1'].includes(invType) && (
                        <InvitatieAniversare1
                            invId={iid}
                            data={{
                                a1s1: invData[`${invType}s1`],
                                a1s2: invData[`${invType}s2`],
                                a1s3: invData[`${invType}s3`],
                            }}
                            isLoggedIn={uid}
                            editMode={true}
                            urlData={{
                                uid,
                                invitationId: iid
                            }}
                            invTitle={invData.title}
                        />
                    )}
                    {invData && ['c1'].includes(invType) && (
                        <InvitatieCorporate1
                            invId={iid}
                            data={{
                                c1s1: invData[`${invType}s1`],
                                c1s2: invData[`${invType}s2`],
                                c1s3: invData[`${invType}s3`],
                            }}
                            isLoggedIn={uid}
                            editMode={true}
                            urlData={{
                                uid,
                                invitationId: iid
                            }}
                            invTitle={invData.title}
                        />
                    )}
                </>
            )}
        </>
    );
}
export default Edit;
