import classes from "./FormAniversare.module.scss";

import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../Components/UI/AlertMessage/AlertMessage";
import { Button } from "@mui/material";
import TextInput from "../../../../Components/UI/Form/TextInput";
import ParticipantiAniversare from "./ParticipantiAniversare/ParticipantiAniversare";
import { saveInvitationAnswer } from "../../../../firebase-invitatii";
import { errorHandler } from "../../../../Helpers/Helpers";
import AddToCalendar from "../AddToCalendar/AddToCalendar";
import FormLogo from "../FormLogo/FormLogo";

const FormAniversare = (props) => {
    const { live, slideData, urlData, slide1Data, slide2Data } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(null);
    const [alertError, setAlertError] = useState(false);
    const [alert, setAlert] = useState({});
    const [topButtonsActive, setTopButtonsActive] = useState(false);
    const [showMainContent, setShowMainContent] = useState(false);
    const [showActions, setShowActions] = useState(false);

    const alreadyAnswered = live && !!localStorage.getItem(`${urlData.invitationId}_aa`);

    const onNumePrenumeChange = (numePrenume) => {
        formik.setFieldValue(
            "numePrenume",
            numePrenume,
            !!formik.touched.numePrenume
        );
        !!numePrenume.trim()
            ? setTopButtonsActive(true)
            : setTopButtonsActive(false);
    };

    const onParticipHandler = () => {
        setShowMainContent(true);
        setShowActions(true);
    };

    const onNuParticipHandler = () => {
        setShowMainContent(false);
        setShowActions(true);
    };

    const onNumarParticipantiChanged = (numarParticipanti) => {
        formik.setFieldValue("numarParticipanti", numarParticipanti, !!formik.touched.numarParticipanti);
    };

    const formik = useFormik({
        initialValues: {
            numePrenume: "",
            numarParticipanti: "",
        },
        validationSchema: Yup.object().shape({
            numePrenume: Yup.string()
                .max(30, "Numele poate conține maxim 30 de caractere!")
                .matches(
                    /^([A-Za-z-ăâîșțĂÂÎȘȚ'" ]*)$/,
                    "Acest câmp acceptă doar diacritice românești!"
                )
                .required("Acest câmp este obligatoriu!"),
            numarParticipanti:
                showMainContent && slideData.showParticipanti &&
                Yup.string().required("Acest câmp este obligatoriu!"),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            setIsSaving(true);
            const formData = {
                nume_prenume: values.numePrenume,
                participa: showMainContent ? "Da" : "Nu",
                numarParticipanti:
                    showMainContent && slideData.showParticipanti
                        ? values.numarParticipanti
                        : false,
            };
            await saveInvitationAnswer(urlData.uid, urlData.invitationId, formData)
                .then(() => {
                    setIsSaving(false);
                    localStorage.setItem(`${urlData.invitationId}_aa`, true);
                    setAlert({
                        severity: "success",
                        title: "Răspuns salvat",
                        description: `Răspunsul dumneavoastră a fost salvat!
                        Vă mulțumim!`,
                    });
                    setSuccess(true);
                })
                .catch((error) => {
                    setAlertError(true);
                    setIsSaving(false);
                    setAlert(errorHandler(error));
                });
        },
    });

    return (
        <div className={classes["aniversare-form-wrapper"]}>
            {alreadyAnswered && !isSaving && !success && !alertError && (
                <div className={classes['alert-wrapper']}>
                    <div className={classes.top}>
                        <p>
                            Se pare că ați răspuns deja la invitație. <br/><br/>
                            Pentru orice modificări sau nelămuriri vă rugăm să contactați pe <strong>{slide1Data.numeSarbatorit}</strong>{" "}
                            (telefon{" "}
                            <a className={classes.anchor} href={`tel:+4${slide1Data.numarSarbatorit || slide1Data.numarCompanie}`}>
                                {slide1Data.numarSarbatorit || slide1Data.numarCompanie}
                            </a>).
                        </p>
                    </div>
                    <div>
                        <FormLogo />
                    </div>
                </div>
            )}
            {isSaving && <Spinner text='Salvare...' />}
            {success && (
                <div className={classes['alert-wrapper']}>
                    <div>
                        <AlertMessage
                            severity={alert.severity}
                            title={alert.title}
                            description={alert.description}
                            refresh={alert.refresh}
                        />
                        <AddToCalendar
                            type="aniversare"
                            name={urlData.url}
                            startDate={slide1Data.dataEveniment}
                            startTime={slide2Data.oraAniversare}
                        />
                    </div>
                    <div>
                        <FormLogo />
                    </div>
                </div>
            )}
            {alertError && (
                <div className={classes['alert-wrapper']}>
                    <AlertMessage
                        severity={alert.severity}
                        title={alert.title}
                        description={alert.description}
                        refresh={alert.refresh}
                    />
                </div>
            )}
            {!alreadyAnswered && !isSaving && !success && !alertError && (
                <form onSubmit={formik.handleSubmit}>
                    <TextInput
                        label='Nume și prenume'
                        value={formik.values.numePrenume}
                        changeHandler={onNumePrenumeChange}
                        error={formik.errors.numePrenume}
                        autoComplete='off'
                    />
                    <div className={classes["top-buttons"]}>
                        <Button
                            color='success'
                            type='button'
                            variant='contained'
                            onClick={onParticipHandler}
                            disabled={!topButtonsActive}
                        >
                            Particip
                        </Button>
                        <Button
                            color='error'
                            type='button'
                            variant='contained'
                            onClick={onNuParticipHandler}
                            disabled={!topButtonsActive}
                        >
                            Nu particip
                        </Button>
                    </div>
                    {showMainContent && slideData.showParticipanti && (
                        <>
                            <h5>Câte persoane veți participa?</h5>
                            <ParticipantiAniversare
                                value={formik.values.numarParticipanti}
                                participantiChanged={onNumarParticipantiChanged}
                                errorParticipanti={formik.errors.numarParticipanti}
                                plural='Număr participanți'
                            />
                        </>
                    )}
                    {showActions && (
                        <>
                            <div className={classes.actions}>
                                <Button
                                    id={classes.submitButton}
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    disabled={!live}
                                >
                                    Salvează
                                </Button>
                                {!live && <p id={classes.submitDisabled}>Butonul de mai sus nu este funcțional în modul demo!</p>}
                            </div>
                        </>
                    )}
                </form>
            )}
        </div>
    );
};

export default FormAniversare;
