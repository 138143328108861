import classes from "./ParticipantiAniversare.module.scss";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const ParticipantiAniversare = (props) => {
    const participantiHandler = (event) => {
        const newParticipantsArray = [];
        props.participantiChanged(event.target.value);
        for (var i = 1; i <= event.target.value; i++) {
            newParticipantsArray.push(i);
        }
    };

    return (
        <div className={classes["participanti-wrapper"]}>
            <FormControl
                className={classes.participanti}
                fullWidth={!props.notFullWidth}
                component='fieldset'
                error={!!props.errorParticipanti || !!props.errorMeniuri}
            >
                {props.plural && <InputLabel>{props.plural}</InputLabel>}
                <Select
                    id={props.plural.trim()}
                    name={props.plural}
                    labelId={`${props.plural.trim()}Label`}
                    label={props.plural}
                    value={props.value}
                    onChange={participantiHandler}
                >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                </Select>
                {props.errorParticipanti && (
                    <FormHelperText>{props.errorParticipanti}</FormHelperText>
                )}
            </FormControl>
        </div>
    );
};

export default ParticipantiAniversare;
