import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../store/modal-slice";
import Url from "../Pages/Invitatii/Components/Url/Url";
import Pachete from "../Pages/Invitatii/Components/Pachete/Pachete";
import AlertMessage from "../Components/UI/AlertMessage/AlertMessage";
import { useAuth, getUserProfile } from "../firebase-auth";
import { fetchPendingInvitations } from "../firebase-invitatii";
import { errorHandler } from "./Helpers";
import Cookies from "js-cookie";

const ta = JSON.parse(sessionStorage.getItem("ta")) ?? false;

let isInitialLoad = true;

export const PendingInvitations = () => {
    const [alert, setAlert] = useState({});
    const userData = useAuth();
    const dispatch = useDispatch();
    const checkedUrl = Cookies.get("checkedUrl");

    useEffect(() => {
        if (isInitialLoad) {
            isInitialLoad = false;
            return;
        }
        if (userData) {
            const updateProfile = async () => {
                const profile = await getUserProfile(userData);
                // Check if user has pending invitations
                const pendingInvitations = async () => {
                    await fetchPendingInvitations(profile.uid)
                        .then((response) => {
                            const iid = response && Object.keys(response)[0],
                                pendingObject = response && response[iid];
                            if (iid && ta) {
                                const invData = { ...pendingObject };
                                invData.pending = false;
                                checkedUrl && (invData.url = checkedUrl);
                                dispatch(
                                    modalActions.modal({
                                        showModal: true,
                                        modalTitle: (pendingObject.url === null && !checkedUrl)
                                            ? "Alege link-ul invitației"
                                            : "Alege pachetul dorit",
                                        closeDisabled: true,
                                        modalContent: (pendingObject.url === null && !checkedUrl) ? (
                                            <Url
                                                data={pendingObject}
                                                isPending
                                                type={pendingObject.type}
                                            />
                                        ) : (
                                            <Pachete
                                                data={invData}
                                                newInv={true}
                                                isPending
                                                type={pendingObject.type}
                                            />
                                        ),
                                    })
                                );
                            }
                        })
                        .catch((err) => {
                            setAlert(errorHandler(err));
                            dispatch(
                                modalActions.modal({
                                    showModal: true,
                                    modalTitle: "Alege pachetul dorit",
                                    modalContent:
                                        <AlertMessage
                                            severity={alert.severity}
                                            title={alert.title}
                                            description={alert.description}
                                            refresh={alert.refresh}
                                        />,
                                })
                            );
                        })
                }
                pendingInvitations();
            };
            updateProfile();
        }
    }, [userData, dispatch, checkedUrl, alert.severity, alert.title, alert.description, alert.refresh]);
}