// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-xJ7lS1ef3_0LS_8TC-f9bKxbaEFO-uY",
  authDomain: "invitatii-2c400.firebaseapp.com",
  projectId: "invitatii-2c400",
  storageBucket: "invitatii-2c400.appspot.com",
  messagingSenderId: "503722870454",
  appId: "1:503722870454:web:a4fd7b23ea4c51956fc817",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const firestore = getFirestore();
export const storage = getStorage(app);
export const functions = getFunctions(app);
