import classes from './SettingsMenu.module.scss';

import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import EditeazaInvitatie from './EditeazaInvitatie/EditeazaInvitatie';
import EditeazaTitlu from './EditeazaTitlu/EditeazaTitlu';
import CumparaRaspunsuri from './CumparaRaspunsuri/CumparaRaspunsuri';
import CopiazaLinkul from './CopiazaLinkul/CopiazaLinkul';

const SettingsMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    const handleClose = () => {
        setAnchorEl(null);
      };
    return (
        <>
            <Tooltip TransitionComponent={Zoom} title="Click pentru mai multe setări" arrow>
                <IconButton
                    id={classes.settingsButton}
                    onClick={handleClick}
                    style={{ maxWidth: "40px", minWidth: "40px" }}
                    aria-label="Setări invitație"
                >
                    <i className="fas fa-ellipsis-v"></i>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="Setări invitație"
                className={classes.menu}
                open={!!anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                aria-controls={anchorEl ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
            >
                <EditeazaInvitatie onClick={handleClose} />
                <EditeazaTitlu onClick={handleClose} />
                <CumparaRaspunsuri onClick={handleClose} />
                <CopiazaLinkul onClick={handleClose} />
            </Menu>
        </>
    );
}

export default SettingsMenu;