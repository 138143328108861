import classes from "./AddToCalendar.module.scss";

import { atcb_action } from "add-to-calendar-button";
import "add-to-calendar-button";
import { Button } from "@mui/material";

const AddToCalendar = (props) => {
    const { type, name, startDate, startTime } = props;
    const addToCalendarHandler = () => {
        if (type === 'aniversare') {
            atcb_action({
                name,
                startDate: new Date(startDate).toISOString().split('T')[0],
                endDate: (new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1))).toISOString().split('T')[0],
                startTime: new Date(startTime).toTimeString().split(' ')[0],
                endTime: "00:00",
                options: ["Apple", "Google"],
                timeZone: "Europe/Bucharest"
            });
        } else if (type === 'nunta') {
            atcb_action({
                name,
                startDate: new Date(startDate).toISOString().split('T')[0],
                endDate: (new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1))).toISOString().split('T')[0],
                options: ["Apple", "Google"],
                timeZone: "Europe/Bucharest"
            });
        }
    };
    return (
        <div id={classes["add-to-calendar"]}>
            <Button
                type='button'
                variant='contained'
                onClick={addToCalendarHandler}
            >
                <i className='fas fa-calendar-plus'></i>&nbsp; Adaugă la Calendar
            </Button>
        </div>
    );
};

export default AddToCalendar;
