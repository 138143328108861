import classes from "./Indicatii.module.scss";

import { Button } from "@mui/material";

const Indicatii = (props) => {
    const goTo = () => {
        window.open(props.goTo, "_blank", "noopener,noreferrer");
    };

    return (
        <Button
            className={classes.indicatii}
            type='button'
            variant='contained'
            onClick={goTo}
        >
            Indicații &nbsp;<i className='fas fa-location-arrow'></i>
        </Button>
    );
};
export default Indicatii;
