import classes from "../../../Form1.module.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import TextInput from "../../../../../Components/UI/Form/TextInput";
import TextareaInput from "../../../../../Components/UI/Form/TextareaInput";
import DatePickerInput from "../../../../../Components/UI/Form/DatePickerInput";
import Spinner from "../../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../../Components/UI/AlertMessage/AlertMessage";
import ImageRadios from "../../../Components/ImageRadios/ImageRadios";
import { saveOrUpdateInvitationData } from "../../../../../firebase-invitatii";
import { errorHandler } from "../../../../../Helpers/Helpers";
import Cookies from "js-cookie";
import TextInputTooltipLegend from "../../../Components/TextInputTooltipLegend/TextInputTooltipLegend";

const FormSlide1 = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState({});
    const [slideImage, setSlideImage] = useState(props.slideData.image);

    const dispatch = useDispatch();

    const onImageChange = (image) => {
        formik.setFieldValue("image", image, !!formik.touched.image);
        setSlideImage(image);
    };

    const onNumeBebeChange = (numeBebe) => {
        formik.setFieldValue("numeBebe", numeBebe, !!formik.touched.numeBebe);
    };

    const onTextChange = (text) => {
        formik.setFieldValue("text", text, !!formik.touched.text);
    };

    const onNumeMamaChange = (numeMama) => {
        formik.setFieldValue(
            "numeMama",
            numeMama,
            !!formik.touched.numeMama
        );
    };

    const onNumarMamaChange = (numarMama) => {
        formik.setFieldValue(
            "numarMama",
            numarMama,
            !!formik.touched.numarMama
        );
    };

    const onNumeTataChange = (numeTata) => {
        formik.setFieldValue("numeTata", numeTata, !!formik.touched.numeTata);
    };

    const onNumarTataChange = (numarTata) => {
        formik.setFieldValue(
            "numarTata",
            numarTata,
            !!formik.touched.numarTata
        );
    };

    const onDataBotezChange = (dataEveniment) => {
        formik.setFieldValue(
            "dataEveniment",
            dayjs(dataEveniment),
            !!formik.touched.dataEveniment
        );
    };

    const onDataConfirmareChange = (dataConfirmare) => {
        formik.setFieldValue(
            "dataConfirmare",
            dayjs(dataConfirmare),
            !!formik.touched.dataConfirmare
        );
    };

    const locallySaveSlide1 = (labelName, dataSlide1, hoursToExpire) => {
        Cookies.set(labelName, JSON.stringify(dataSlide1), { expires: 1 / 24 * hoursToExpire });
    };

    const invitatiileMeleHandler = () => {
        window.location.pathname = '/invitatiile-mele';
    }

    const formik = useFormik({
        initialValues: {
            iid: props.slideData.iid,
            image: props.slideData.image,
            numeBebe: props.slideData.numeBebe,
            text: props.slideData.text,
            numeMama: props.slideData.numeMama,
            numarMama: props.slideData.numarMama,
            numeTata: props.slideData.numeTata,
            numarTata: props.slideData.numarTata,
            dataEveniment: dayjs(props.slideData.dataEveniment),
            dataConfirmare: dayjs(props.slideData.dataConfirmare),
        },
        validationSchema: Yup.object().shape({
            image: Yup.object(),
            numeBebe: Yup.string().required("Numele copilului este obligatoriu!"),
            text: Yup.string().required("Textul invitației este obligatoriu!"),
            numeMama: Yup.string().required(
                "Numele mamei este obligatoriu!"
            ),
            numarMama: Yup.string()
                .required("Numărul de telefon al mamei este obligatoriu!")
                .matches(/^07[0-9]{8}$/, "Număr de telefon invalid!"),
            numeTata: Yup.string().required("Numele tatălui este obligatoriu!"),
            numarTata: Yup.string()
                .required("Numărul de telefon al tatălui este obligatoriu!")
                .matches(/^07[0-9]{8}$/, "Număr de telefon invalid!"),
            dataEveniment: Yup.date().required("Introduceți data botezului"),
            dataConfirmare: Yup.date()
                .required("Introduceți data de confirmare a prezenței")
                .max(
                    Yup.ref("dataEveniment"),
                    "Data confirmării trebuie să fie înainte de data evenimentului!"
                ),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            setIsSaving(true);
            const slide1Data = {
                iid: values.iid,
                image: {
                    value: slideImage.value,
                    url: slideImage.url
                        ? slideImage.url
                        : `/Media/Images/Modele/Botez/${slideImage.value}`,
                },
                numeBebe: values.numeBebe,
                text: values.text,
                numeMama: values.numeMama,
                numarMama: values.numarMama,
                numeTata: values.numeTata,
                numarTata: values.numarTata,
                dataEveniment: values.dataEveniment,
                dataConfirmare: dayjs(values.dataConfirmare),
            };
            props.setEventDate(values.dataEveniment);
            props.setSlideData(slide1Data);
            if (props.editMode) {
                const invData = {
                    b1s1: JSON.parse(Cookies.get(`${props.invId}_b1s1`)),
                    b1s2: JSON.parse(Cookies.get(`${props.invId}_b1s2`)),
                    b1s3: JSON.parse(Cookies.get(`${props.invId}_b1s3`)),
                };
                locallySaveSlide1(`${invData.b1s1.iid}_b1s1`, slide1Data, 1);
                invData.b1s1 = JSON.parse(Cookies.get(`${invData.b1s1.iid}_b1s1`));
                await saveOrUpdateInvitationData(
                    props.uid,
                    'b1',
                    false,
                    invData,
                    false,
                    props.invTitle
                    )
                    .then(() => {
                        setIsSaving(false);
                        setAlert({
                            severity: "success",
                            title: "Modificări salvate",
                            description: "Modificările au fost salvate cu succes!",
                        });
                        setSuccess(true);
                    })
                    .catch((error) => {
                        setError(true);
                        setIsSaving(false);
                        setAlert(errorHandler(error));
                    });
            } else {
                locallySaveSlide1('b1s1', slide1Data, 24);
                setIsSaving(false);
                props.slideUpdated(true);
                setAlert({
                    severity: "success",
                    title: "Modificări salvate",
                    description: "Modificările au fost salvate cu succes!",
                });
                setSuccess(true);
                setTimeout(() => {
                    dispatch(modalActions.close());
                }, 750);
            }
        },
    });

    return (
        <div className={classes["form-wrapper"]}>
            {isSaving && <Spinner text='Salvare...' />}
            {error && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <>
                    <AlertMessage
                        severity={alert.severity}
                        title={alert.title}
                        description={alert.description}
                        refresh={alert.refresh}
                    />
                    {props.editMode && (
                        <Button
                            id={classes.submitButton}
                            type='button'
                            variant='contained'
                            onClick={invitatiileMeleHandler}
                        >
                            Înapoi la "Invitațiile mele"
                        </Button>
                    )}
                </>
            )}
            {!isSaving && !success && !error && (
                <form onSubmit={formik.handleSubmit}>
                    <ImageRadios
                        default={props.slideData.image}
                        text='Poză stânga, vizibilă doar pe ecrane mari (PC):'
                        labels={[
                            "b1s1_1",
                            "b1s1_2",
                            "b1s1_3",
                            "b1s1_4",
                            "b1s1_5",
                            "b1s1_6",
                            "b1s1_7",
                            "b1s1_8",
                            "b1s1_9",
                        ]}
                        addItem={{
                            imageId: formik.values.iid,
                            type: "b1s1_99",
                            path: "Botez",
                            url:
                                props.slideData.image.value === "b1s1_99" &&
                                props.slideData.image.url,
                        }}
                        path='Botez'
                        changeHandler={onImageChange}
                    />
                    <TextInput
                        label='Nume bebe'
                        value={formik.values.numeBebe}
                        changeHandler={onNumeBebeChange}
                        error={formik.errors.numeBebe}
                    />
                    <TextareaInput
                        label={<TextInputTooltipLegend />}
                        value={formik.values.text}
                        placeholder='Text'
                        changeHandler={onTextChange}
                        error={formik.errors.text}
                    />
                    <div className={classes["line-wrapper"]}>
                        <TextInput
                            notFullWidth
                            label='Nume mamă'
                            value={formik.values.numeMama}
                            changeHandler={onNumeMamaChange}
                            error={formik.errors.numeMama}
                        />
                        <TextInput
                            notFullWidth
                            label='Număr telefon mamă'
                            value={formik.values.numarMama}
                            placeholder='ex: 0711222333'
                            changeHandler={onNumarMamaChange}
                            error={formik.errors.numarMama}
                        />
                    </div>
                    <div className={classes["line-wrapper"]}>
                        <TextInput
                            notFullWidth
                            label='Nume tată'
                            value={formik.values.numeTata}
                            changeHandler={onNumeTataChange}
                            error={formik.errors.numeTata}
                        />
                        <TextInput
                            notFullWidth
                            label='Număr telefon tată'
                            value={formik.values.numarTata}
                            placeholder='ex: 0711222333'
                            changeHandler={onNumarTataChange}
                            error={formik.errors.numarTata}
                        />
                    </div>
                    {formik.errors.dataConfirmare && (
                        <div className={classes["line-wrapper"]}>
                            <span className={classes.invalid}>
                                {formik.errors.dataConfirmare}
                            </span>
                        </div>
                    )}
                    <div className={classes["line-wrapper"]}>
                        <DatePickerInput
                            notFullWidth
                            disablePast
                            label='Data botezului'
                            value={formik.values.dataEveniment}
                            changeHandler={onDataBotezChange}
                            error={formik.errors.dataEveniment}
                        />
                        <DatePickerInput
                            notFullWidth
                            disablePast
                            label='Data confirmării'
                            value={formik.values.dataConfirmare}
                            changeHandler={onDataConfirmareChange}
                            error={formik.errors.dataConfirmare}
                        />
                    </div>
                    <Button
                        id={classes.submitButton}
                        type='submit'
                        variant='contained'
                    >
                        Actualizare
                    </Button>
                </form>
            )}
        </div>
    );
};

export default FormSlide1;
