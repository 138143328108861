import './FadeAnimation.scss';

import { useState, useEffect, useRef } from "react";

const FadeAnimation = (props) => {
    const { animation } = props;
    const [isVisible, setVisible] = useState(true);
    const domRef = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        const current = domRef.current
        observer.observe(current);
        return () => observer.unobserve(current);
    }, []);

    return (
        <div
            className={`${animation} ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
        >
            {props.children}
        </div>
    );
}

export default FadeAnimation;
