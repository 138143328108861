import classes from "../../../Slides1.module.scss";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import dayjs from "dayjs";
import EditInvitatie from "../../../Components/Edit/Edit";
import FormSlide2 from "./FormSlide2";
import LocationBlock from "../../../Components/LocationBlock/LocationBlock";
import Cookies from "js-cookie";

export const defaultSlide2Data = {
    locatieAniversare:
        "zai mini-a-ture, Piața Unirii, Timișoara, Romania",
    denumireLocatieAniversare: "zai mini-a-ture",
    adresaAniversare: "Piața Unirii, Timișoara, Romania",
    oraAniversare: dayjs(new Date(1687618800000)),
    urlAniversare:
        "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=ChIJt6aswoFnRUcRAkIWuE3pWOs",
    color: {
        value: "dodgerblue",
        url: "/Media/Images/Modele/Nunta/dodgerblue",
    },
};

const Slide2 = (props) => {
    const [initialRender, setInitialRender] = useState(true);
    const [slide2Data, setSlide2Data] = useState(defaultSlide2Data);

    const savedSlide2Data = props.invId
        ? Cookies.get(`${props.invId}_a1s2`)
        : Cookies.get("a1s2");

    useEffect(() => {
        if (initialRender && !!savedSlide2Data) {
            setSlide2Data(JSON.parse(savedSlide2Data));
            setInitialRender(false);
        }
    }, [initialRender, savedSlide2Data]);

    const dispatch = useDispatch();

    const openModalSlide2 = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Editează a doua pagină",
                modalContent: (
                    <FormSlide2
                        uid={props.uid}
                        invId={props.invId}
                        invTitle={props.invTitle}
                        slideData={slide2Data}
                        setSlideData={setSlide2Data}
                        slideUpdated={props.slideUpdated}
                        editMode={props.editMode}
                    />
                ),
            })
        );
    };

    // Function to process TimePicker date
    const convertToTime = (data) => {
        const hours = new Date(data).getHours().toString();
        const minutes = new Date(data).getMinutes().toString();
        const processedMinutes = minutes.length === 1 ? `0${minutes}` : minutes;
        return `${hours}:${processedMinutes}`;
    };

    return (
        <div id={classes.slide_2}>
            {!props.live && (
                <EditInvitatie
                    tooltipText='Editează a doua pagină'
                    openModal={openModalSlide2}
                    slide={2}
                />
            )}
            <div className={classes['locations-wrapper']}>
                <LocationBlock
                    icon='petrecere'
                    title='NE VEDEM LA'
                    location={slide2Data.denumireLocatieAniversare}
                    locationUrl={slide2Data.urlAniversare}
                    address={slide2Data.adresaAniversare}
                    hour={convertToTime(slide2Data.oraAniversare)}
                    color={slide2Data.color.value}
                />
            </div>
        </div>
    );
};

export default Slide2;
