import classes from "../../../Form1.module.scss";
import styles from "../../../FormSlide2.module.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import CheckBox from "../../../../../Components/UI/Form/CheckBox";
import TextInput from "../../../../../Components/UI/Form/TextInput";
import TimePickerInput from "../../../../../Components/UI/Form/TimePickerInput";
import Spinner from "../../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../../Components/UI/AlertMessage/AlertMessage";
import LocationAutocomplete from "../../../Components/LocationAutocomplete/LocationAutocomplete";
import ImageRadios from "../../../Components/ImageRadios/ImageRadios";
import { saveOrUpdateInvitationData } from "../../../../../firebase-invitatii";
import { errorHandler } from "../../../../../Helpers/Helpers";
import Cookies from "js-cookie";

const FormSlide2 = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState({});
    const [showBiserica, setShowBiserica] = useState(
        props.slideData.biserica
    );
    const [showPetrecere, setShowPetrecere] = useState(
        props.slideData.petrecere
    );

    const dispatch = useDispatch();

    const locallySaveSlide2 = (labelName, dataSlide2, hoursToExpire) => {
        Cookies.set(labelName, JSON.stringify(dataSlide2), { expires: 1 / 24 * hoursToExpire });
    };

    // BISERICĂ
    const onBisericaChange = (biserica) => {
        formik.setFieldValue(
            "biserica",
            biserica,
            !!formik.touched.biserica
        );
        setShowBiserica(biserica);
    };

    const onDenumireLocatieBisericaChange = (
        denumire_locatie_biserica
    ) => {
        formik.setFieldValue(
            "denumireLocatieBiserica",
            denumire_locatie_biserica,
            !!formik.touched.denumireLocatieBiserica
        );
    };

    const onAdresaBisericaChange = (adresa_biserica) => {
        formik.setFieldValue(
            "adresaBiserica",
            adresa_biserica,
            !!formik.touched.adresaBiserica
        );
    };

    const onOraBisericaChange = (ora_biserica) => {
        formik.setFieldValue(
            "oraBiserica",
            ora_biserica,
            !!formik.touched.oraBiserica
        );
    };

    const onLocatieBisericaChange = (data) => {
        formik.setFieldValue(
            "locatieBiserica",
            data.description,
            !!formik.touched.locatieBiserica
        );
        formik.setFieldValue(
            "denumireLocatieBiserica",
            data.description?.split(",")["0"],
            !!formik.touched.locatieBiserica
        );
        if (data.description) {
            formik.setFieldValue(
                "adresaBiserica",
                data.description?.replace(
                    `${data.description?.split(",")["0"]},`,
                    ""
                ),
                !!formik.touched.locatieBiserica
            );
            formik.setFieldValue(
                "urlBiserica",
                `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${data.place_id}`,
                !!formik.touched.locatieBiserica
            );
        }
    };

    // PETRECERE
    const onPetrecereChange = (petrecere) => {
        formik.setFieldValue(
            "petrecere",
            petrecere,
            !!formik.touched.petrecere
        );
        setShowPetrecere(petrecere);
    };

    const onDenumireLocatiePetrecereChange = (denumire_locatie_petrecere) => {
        formik.setFieldValue(
            "denumireLocatiePetrecer",
            denumire_locatie_petrecere,
            !!formik.touched.denumireLocatiePetrecere
        );
    };

    const onAdresaPetrecereChange = (adresa_petrecere) => {
        formik.setFieldValue(
            "adresaPetrecere",
            adresa_petrecere,
            !!formik.touched.adresaPetrecere
        );
    };

    const onOraPetrecereChange = (ora_petrecere) => {
        formik.setFieldValue(
            "oraPetrecere",
            ora_petrecere,
            !!formik.touched.oraPetrecere
        );
    };

    const onLocatiePetrecereChange = (data) => {
        formik.setFieldValue(
            "locatiePetrecere",
            data.description,
            !!formik.touched.locatiePetrecere
        );
        formik.setFieldValue(
            "denumireLocatiePetrecere",
            data.description?.split(",")["0"],
            !!formik.touched.locatiePetrecere
        );
        if (data.description) {
            formik.setFieldValue(
                "adresaPetrecere",
                data.description?.replace(
                    `${data.description?.split(",")["0"]},`,
                    ""
                ),
                !!formik.touched.locatiePetrecere
            );
            formik.setFieldValue(
                "urlPetrecere",
                `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${data.place_id}`,
                !!formik.touched.locatiePetrecere
            );
        }
    };

    const onColorChange = (color) => {
        formik.setFieldValue("color", color, !!formik.touched.color);
    };

    const invitatiileMeleHandler = () => {
        window.location.pathname = '/invitatiile-mele';
    }

    const formik = useFormik({
        initialValues: {
            biserica: props.slideData.biserica,
            locatieBiserica: props.slideData.locatieBiserica,
            denumireLocatieBiserica: props.slideData.denumireLocatieBiserica,
            adresaBiserica: props.slideData.adresaBiserica,
            oraBiserica: dayjs(props.slideData.oraBiserica),
            urlBiserica: props.slideData.urlBiserica,
            petrecere: props.slideData.petrecere,
            locatiePetrecere: props.slideData.locatiePetrecere,
            denumireLocatiePetrecere: props.slideData.denumireLocatiePetrecere,
            adresaPetrecere: props.slideData.adresaPetrecere,
            oraPetrecere: dayjs(props.slideData.oraPetrecere),
            urlPetrecere: props.slideData.urlPetrecere,
            color: props.slideData.color,
        },
        validationSchema: Yup.object()
            .shape({
                biserica: Yup.bool(),
                locatieBiserica: Yup.string().required(
                    "Locația unde are loc ceremonia religioasă este oblicatorie!"
                ),
                denumireLocatieBiserica: Yup.string().required(
                    "Denumirea locației unde are loc ceremonia religioasă este oblicatorie!"
                ),
                adresaBiserica: Yup.string().required(
                    "Adresa locației unde are loc ceremonia religioasă este oblicatorie!"
                ),
                oraBiserica: Yup.string().required(
                    "Ora la care are loc ceremonia religioasă este oblicatorie!"
                ),
                petrecere: Yup.bool(),
                locatiePetrecere: Yup.string().required(
                    "Locația unde are loc petrecerea este oblicatorie!"
                ),
                denumireLocatiePetrecere: Yup.string().required(
                    "Denumirea locației unde are loc petrecerea este oblicatorie!"
                ),
                adresaPetrecere: Yup.string().required(
                    "Adresa locației unde are loc petrecerea este oblicatorie!"
                ),
                oraPetrecere: Yup.string().required(
                    "Ora la care are loc petrecerea este oblicatorie!"
                ),
            })
            .test("checkBoxesTest", null, (obj) => {
                if (
                    obj.biserica ||
                    obj.petrecere
                ) {
                    return true;
                }
                return new Yup.ValidationError(
                    "Cel puțin una dintre secțiuni trebuie să fie activată!",
                    null,
                    "minOneLocation"
                );
            }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            setIsSaving(true);
            const slide2Data = {
                biserica: values.biserica,
                locatieBiserica: values.locatieBiserica,
                denumireLocatieBiserica: values.denumireLocatieBiserica,
                adresaBiserica: values.adresaBiserica,
                oraBiserica: values.oraBiserica,
                urlBiserica: values.urlBiserica,
                petrecere: values.petrecere,
                locatiePetrecere: values.locatiePetrecere,
                denumireLocatiePetrecere: values.denumireLocatiePetrecere,
                adresaPetrecere: values.adresaPetrecere,
                oraPetrecere: values.oraPetrecere,
                urlPetrecere: values.urlPetrecere,
                color: values.color,
            };
            props.setSlideData(slide2Data);
            if (props.editMode) {
                const invData = {
                    b1s1: JSON.parse(Cookies.get(`${props.invId}_b1s1`)),
                    b1s2: JSON.parse(Cookies.get(`${props.invId}_b1s2`)),
                    b1s3: JSON.parse(Cookies.get(`${props.invId}_b1s3`)),
                };
                locallySaveSlide2(`${invData.b1s1.iid}_b1s2`, slide2Data, 1);
                invData.b1s2 = JSON.parse(Cookies.get(`${invData.b1s1.iid}_b1s2`));
                await saveOrUpdateInvitationData(
                    props.uid,
                    'b1',
                    false,
                    invData,
                    false,
                    props.invTitle
                    )
                    .then(() => {
                        setIsSaving(false);
                        setAlert({
                            severity: "success",
                            title: "Modificări salvate",
                            description: "Modificările au fost salvate cu succes!",
                        });
                        setSuccess(true);
                    })
                    .catch((error) => {
                        setError(true);
                        setIsSaving(false);
                        setAlert(errorHandler(error));
                    });
            } else {
                locallySaveSlide2('b1s2', slide2Data, 24);
                setIsSaving(false);
                props.slideUpdated(true);
                setAlert({
                    severity: "success",
                    title: "Modificări salvate",
                    description: `Modificările au fost salvate cu succes!`,
                });
                setSuccess(true);
                setTimeout(() => {
                    dispatch(modalActions.close());
                }, 750);
            }
        },
    });

    return (
        <div className={`${classes["form-wrapper"]} ${styles["form-slide-2"]}`}>
            {isSaving && <Spinner text='Salvare...' />}
            {error && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <>
                    <AlertMessage
                        severity={alert.severity}
                        title={alert.title}
                        description={alert.description}
                        refresh={alert.refresh}
                    />
                    {props.editMode && (
                        <Button
                            id={classes.submitButton}
                            type='button'
                            variant='contained'
                            onClick={invitatiileMeleHandler}
                        >
                            Înapoi la "Invitațiile mele"
                        </Button>
                    )}
                </>
            )}
            {!isSaving && !success && !error && (
                <form onSubmit={formik.handleSubmit}>
                    <p>
                        În funcție de evenimentul dumneavoastră activați sau
                        dezactivați secțiunile de care aveți nevoie.
                    </p>
                    {/* BISERICA */}
                    <Box className={styles.location}>
                        <CheckBox
                            label='Biserica'
                            value={formik.values.biserica}
                            changeHandler={onBisericaChange}
                        />
                        <div className={styles["location-inputs"]}>
                            <LocationAutocomplete
                                label='Caută locație'
                                noLocation='Nicio locație'
                                value={formik.values.locatieBiserica}
                                changeHandler={onLocatieBisericaChange}
                                error={formik.errors.locatieBiserica}
                                disabled={!showBiserica}
                            />
                            <TextInput
                                label='Denumire locație'
                                value={
                                    formik.values
                                        .denumireLocatieBiserica
                                }
                                placeholder='Biserica Millenium'
                                changeHandler={
                                    onDenumireLocatieBisericaChange
                                }
                                error={
                                    formik.errors
                                        .denumireLocatieBiserica
                                }
                                disabled={!showBiserica}
                            />
                            <TextInput
                                label='Adresa'
                                value={formik.values.adresaBiserica}
                                placeholder='Piaţa Romanilor Nr. 2, Timișoara'
                                changeHandler={onAdresaBisericaChange}
                                error={formik.errors.adresaBiserica}
                                disabled={!showBiserica}
                            />
                            <TimePickerInput
                                label='Ora'
                                value={dayjs(formik.values.oraBiserica)}
                                changeHandler={onOraBisericaChange}
                                error={formik.errors.oraBiserica}
                                disabled={!showBiserica}
                            />
                        </div>
                    </Box>
                    <hr className={styles.hr} />
                    {/* PETRECEREA */}
                    <Box className={styles.location}>
                        <CheckBox
                            label='Petrecerea'
                            value={formik.values.petrecere}
                            changeHandler={onPetrecereChange}
                            error={formik.errors.petrecere}
                        />
                        <div className={styles["location-inputs"]}>
                            <LocationAutocomplete
                                label='Caută locație'
                                noLocation='Nicio locație'
                                value={formik.values.locatiePetrecere}
                                changeHandler={onLocatiePetrecereChange}
                                error={formik.errors.locatiePetrecere}
                                disabled={!showPetrecere}
                            />
                            <TextInput
                                label='Denumire locație'
                                value={formik.values.denumireLocatiePetrecere}
                                placeholder="Anne's Event Ballroom"
                                changeHandler={onDenumireLocatiePetrecereChange}
                                error={formik.errors.denumireLocatiePetrecere}
                                disabled={!showPetrecere}
                            />
                            <TextInput
                                label='Adresa'
                                value={formik.values.adresaPetrecere}
                                placeholder='Strada Căliman, Nr. 1, Moșnița Nouă'
                                changeHandler={onAdresaPetrecereChange}
                                error={formik.errors.adresaPetrecere}
                                disabled={!showPetrecere}
                            />
                            <TimePickerInput
                                label='Ora'
                                value={dayjs(formik.values.oraPetrecere)}
                                changeHandler={onOraPetrecereChange}
                                error={formik.errors.oraPetrecere}
                                disabled={!showPetrecere}
                            />
                        </div>
                    </Box>
                    <hr className={styles.hr} />
                    <ImageRadios
                        default={props.slideData.color}
                        text='Culoarea chenarelor:'
                        labels={[
                            "darkorange",
                            "deeppink",
                            "dodgerblue",
                            "gold",
                            "limegreen",
                            "maroon",
                            "mediumturquoise",
                            "burlywood",
                            "red",
                            "violet",
                        ]}
                        path='Nunta'
                        changeHandler={onColorChange}
                    />
                    {formik.errors.minOneLocation && (
                        <p className='error-alert'>
                            {formik.errors.minOneLocation}
                        </p>
                    )}
                    <Button type='submit' variant='contained'>
                        Actualizare
                    </Button>
                    {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
                </form>
            )}
        </div>
    );
};

export default FormSlide2;
