
import classes from "./Thanks.module.scss";

import { useState } from "react";
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Cookies from "js-cookie";

const Thanks = (props) => {
    const url = JSON.parse(Cookies.get("checkedUrl")),
        [linkCopied, setLinkCopied] = useState(false),
        host = window.location.hostname,
        root = host === 'localhost' ? 'http://localhost:3000' : `https://${host}`;

    const copyToClipboard = () => {
        setLinkCopied(true);
        navigator.clipboard.writeText(`${root}/i/${url}`);
    }

    // https://fwuensche.medium.com/react-button-to-copy-to-clipboard-75ef5ecdc708
    return (
        <div className={classes.thanks}>
            {url && <>
                <p>Felicitări! Ai activat <strong>{props.invites} de răspunsuri</strong> pentru invitația ta.</p>
                <p><strong>În 2-3 minute</strong> invitația va putea fi accesată prin link-ul de mai jos:</p>
                <div className={classes.link}>
                    <Tooltip
                        TransitionComponent={Zoom}
                        title="Click pentru a copia link-ul"
                        arrow
                    >
                        <Box
                            component="button"
                            sx={{ p: 1, border: '1px dashed grey' }}
                            onClick={copyToClipboard}
                        >
                            {`${root}/i/${url}`}
                        </Box>
                    </Tooltip>
                    {linkCopied && <small>Link-ul a fost copiat.</small>}
                </div>
            </>}
            <p>Invită-ți prietenii și/sau rudele la eveniment:<br />
                SHARE ICONS FOR WHATSAPP, FACEBOOK, GMAIL GO HERE...</p>
            <p>Pe măsură ce invitații îți vor răspunde, vei primi email și vei putea vedea răspunsurile în contul tău la secțiunea <a href="/invitatiile-mele">"Invitațiile mele"</a>.</p>
            <p>Oh, și nu în ultimul rând, mulțumim că ne-ai ales să îți fim alături la această ocazie specială.</p>
        </div>
    );
}

export default Thanks;