import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { signUp } from "../../../firebase-auth";
import { errorHandler } from "../../../Helpers/Helpers";

import classes from "../Auth.module.scss";
import { Button } from "@mui/material";
import Spinner from "../../UI/Spinner/Spinner";
import AlertMessage from "../../UI/AlertMessage/AlertMessage";
import TextInput from "../../UI/Form/TextInput";

const SignUp = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState({});
  const [error, setError] = useState(false);

  const switchToLogin = () => {
    props.show("login");
  };

  const onFirstNameChange = (firstName) => {
    formik.setFieldValue("firstName", firstName, !!formik.touched.firstName);
  };
  const onLastNameChange = (lastName) => {
    formik.setFieldValue("lastName", lastName, !!formik.touched.lastName);
  };
  const onEmailChange = (email) => {
    formik.setFieldValue("email", email, !!formik.touched.email);
  };
  const onPasswordChange = (pass) => {
    formik.setFieldValue("password", pass, !!formik.touched.password);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Introduceți un prenume valid"),
      lastName: Yup.string().required("Introduceți un nume valid"),
      email: Yup.string()
        .email("Introduceți o adresă de email validă")
        .required("Introduceți o adresă de email"),
      password: Yup.string()
        .required("Introduceți o parolă")
        .min(8, "Parolă prea scurtă - trebuie sa aibă minim 8 caractere")
        .matches(/[a-zA-Z]/, "Parola poate conține doar caractere latine")
        .matches(
          /[@#$%^&+!=]/,
          "Parola trebuie să conțina minim un caracter special ( @,#,$,%,^,&,+,!,= )"
        ),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      setIsSaving(true);
      const profile = {
        user_created: new Date(),
        first_name: values.firstName,
        last_name: values.lastName,
      };
      const invitationData = {
        invitationObject: props.data,
        invitationType: props.invitationType,
        url: null,
        pending: true
      };
      signUp(values.email, values.password, profile, invitationData)
        .then(() => {
          setAlert({
            severity: "success",
            title: "Contul dumneavoastră a fost creat",
            description: `Vă rugăm activați-vă contul accesând link-ul de activare primit la adresa ${values.email}!`,
          });
          setSuccess(true);
          sessionStorage.setItem('user_created', true);
        })
        .catch((error) => {
          setAlert(errorHandler(error));
          setSuccess(false);
          setError(true);
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
  });

  return (
    <div className={classes["sign-up"]}>
      {isSaving && <Spinner text="Se salvează..." />}
      {error && (
        <AlertMessage
          severity={alert.severity}
          title={alert.title}
          description={alert.description}
          refresh={alert.refresh}
        />
      )}
      {success && (
        <AlertMessage
          severity={alert.severity}
          title={alert.title}
          description={alert.description}
          refresh={alert.refresh}
        />
      )}
      {!isSaving && !success && !error && (
        <form onSubmit={formik.handleSubmit}>
          {!!props.fromInvitation && <p>Pentru a putea continua va trebui să te loghezi sau să îți faci un cont pe site-ul nostru. Astfel, vei avea acces la răspunsurile celor cărora le vei trimite invitația, toate centralizate într-un singur loc.</p>}
          <TextInput
            label="Nume"
            changeHandler={onLastNameChange}
            error={formik.errors.lastName}
          />
          <TextInput
            label="Prenume"
            changeHandler={onFirstNameChange}
            error={formik.errors.firstName}
          />
          <TextInput
            label="Email"
            changeHandler={onEmailChange}
            error={formik.errors.email}
          />
          <TextInput
            label="Parolă"
            changeHandler={onPasswordChange}
            error={formik.errors.password}
            secret
          />
          <p>
            Ai cont?{" "}
            <span className="anchor" onClick={switchToLogin}>
              Loghează-te!
            </span>
          </p>
          <Button id={classes.submitButton} type="submit" variant="contained">
            Înregistrează-te
          </Button>
        </form>
      )}
    </div>
  );
};

export default SignUp;
