import classes from "./Navigation.module.scss";

import Logo from "../../by-logo.svg";
import { Link } from "react-router-dom";
import Auth from "../Auth/Auth";
import { logout } from "../../firebase-auth";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { modalActions } from "../../store/modal-slice";
import { useCheckMobileScreen } from "../../Helpers/Helpers";
import { Link as AnchorLink } from "@mui/material";

const Navigation = (props) => {
    const dispatch = useDispatch();

    const logoutHandler = () => {
        logout();
        dispatch(authActions.logoutUser());
    };

    const openModal = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Intră în cont",
                modalContent: <Auth />,
            })
        );
    };

    const isMobileScreen = useCheckMobileScreen();
    const closeNavBar = () => {
        const toggleButton = document.getElementById("toggler");
        isMobileScreen && toggleButton.click();
    };
    return (
        <div id={classes["navigation-wrapper"]} className="container">
            <nav
                className={`navbar navbar-expand-lg navbar-light ${classes.navbar}`}
            >
                <a className={`navbar-brand ${classes['logo-wrapper']}`} href="/">
                    <div>
                        <img src={Logo} alt='Happy Events' />
                    </div>
                </a>
                <button
                    id="toggler"
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div
                    className="collapse navbar-collapse justify-content-center"
                    id="navbarSupportedContent"
                    style={{width: "100%"}}
                >
                    <ul
                        className={`navbar-nav mb-2 mb-lg-0 ${classes["navbar-nav"]}`}
                    >
                        <li
                            className={`nav-item ${classes["animated-item"]}`}
                            key="acasa"
                            onClick={closeNavBar}
                        >
                            <Link to="/" id="acasa" className="acasa">
                                Acasă
                            </Link>
                        </li>
                        <li
                            className={`nav-item ${classes["animated-item"]}`}
                            key="despre"
                            onClick={closeNavBar}
                        >
                            <Link
                                to="/despre-noi"
                                id="despre"
                                className="despre"
                            >
                                Despre noi
                            </Link>
                        </li>
                        {/* INVITAȚII */}
                        <li
                            className={`nav-item dropdown ${classes["animated-item"]}`}
                        >
                            <AnchorLink
                                className="nav-link dropdown-toggle"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                            >
                                Invitații
                            </AnchorLink>
                            <ul
                                className={`dropdown-menu ${classes.dropdown}`}
                            >
                                {/* NUNTĂ */}
                                <li className={`nav-item dropend ${classes["animated-item"]} ${classes["nav-item"]}`}>
                                    <AnchorLink
                                        className="nav-link dropdown-toggle"
                                        id="nuntaDropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                    >
                                        Nuntă
                                    </AnchorLink>
                                    <div
                                        className={`dropdown-menu ${classes.dropdown}`}
                                        aria-labelledby="nuntaDropdown"
                                    >
                                        <Link
                                            to="/invitatii/nunta/n1"
                                            id="invitatie-nunta-1"
                                            className={`dropdown-item ${classes["animated-item"]}`}
                                            target="_blank"
                                        >
                                            Clasic
                                        </Link>
                                        {/* <hr className="dropdown-divider" />
                                        <Link
                                            to="/invitatii/nunta/n1"
                                            id="invitatie-nunta-2"
                                            className={`dropdown-item ${classes["animated-item"]}`}
                                            target="_blank"
                                        >
                                            Rustic
                                        </Link> */}
                                    </div>
                                </li>
                                <hr className="dropdown-divider" />
                                {/* BOTEZ */}
                                <li className={`nav-item dropend ${classes["animated-item"]} ${classes["nav-item"]}`}>
                                    <AnchorLink
                                        className="nav-link dropdown-toggle"
                                        id="botezDropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                    >
                                        Botez
                                    </AnchorLink>
                                    <div
                                        className={`dropdown-menu ${classes.dropdown}`}
                                        aria-labelledby="botezDropdown"
                                    >
                                        <Link
                                            to="/invitatii/botez/b1"
                                            id="invitatie-botez-1"
                                            className={`dropdown-item ${classes["animated-item"]}`}
                                            target="_blank"
                                        >
                                            Clasic
                                        </Link>
                                        {/* <hr className="dropdown-divider" />
                                        <Link
                                            to="/invitatii/nunta/n1"
                                            id="invitatie-nunta-2"
                                            className={`dropdown-item ${classes["animated-item"]}`}
                                            target="_blank"
                                        >
                                            Rustic
                                        </Link> */}
                                    </div>
                                </li>
                                <hr className="dropdown-divider" />
                                {/* NUNTĂ ȘI BOTEZ */}
                                <li className={`nav-item dropend ${classes["animated-item"]} ${classes["nav-item"]}`}>
                                    <AnchorLink
                                        className="nav-link dropdown-toggle"
                                        id="nuntaBotezDropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                    >
                                        Nuntă și botez
                                    </AnchorLink>
                                    <div
                                        className={`dropdown-menu ${classes.dropdown}`}
                                        aria-labelledby="nuntaBotezDropdown"
                                    >
                                        <Link
                                            to="/invitatii/nunta-botez/nb1"
                                            id="invitatie-nunta-botez-1"
                                            className={`dropdown-item ${classes["animated-item"]}`}
                                            target="_blank"
                                        >
                                            Clasic
                                        </Link>
                                        {/* <hr className="dropdown-divider" />
                                        <Link
                                            to="/invitatii/nunta/n1"
                                            id="invitatie-nunta-2"
                                            className={`dropdown-item ${classes["animated-item"]}`}
                                            target="_blank"
                                        >
                                            Rustic
                                        </Link> */}
                                    </div>
                                </li>
                                <hr className="dropdown-divider" />
                                {/* ANIVERSARE */}
                                <li className={`nav-item dropend ${classes["animated-item"]} ${classes["nav-item"]}`}>
                                    <AnchorLink
                                        className="nav-link dropdown-toggle"
                                        id="aniversareDropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                    >
                                        Aniversare
                                    </AnchorLink>
                                    <div
                                        className={`dropdown-menu ${classes.dropdown}`}
                                        aria-labelledby="aniversareDropdown"
                                    >
                                        <Link
                                            to="/invitatii/aniversare/a1"
                                            id="invitatie-aniversare-1"
                                            className={`dropdown-item ${classes["animated-item"]}`}
                                            target="_blank"
                                        >
                                            Clasic
                                        </Link>
                                        {/* <hr className="dropdown-divider" />
                                        <Link
                                            to="/invitatii/aniversare/a1"
                                            id="invitatie-aniversare-2"
                                            className={`dropdown-item ${classes["animated-item"]}`}
                                            target="_blank"
                                        >
                                            Rustic
                                        </Link> */}
                                    </div>
                                </li>
                                <hr className="dropdown-divider" />
                                {/* CORPORATE */}
                                <li className={`nav-item dropend ${classes["animated-item"]} ${classes["nav-item"]}`}>
                                    <AnchorLink
                                        className="nav-link dropdown-toggle"
                                        id="corporateDropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="outside"
                                    >
                                        Corporate
                                    </AnchorLink>
                                    <div
                                        className={`dropdown-menu ${classes.dropdown}`}
                                        aria-labelledby="corporateDropdown"
                                    >
                                        <Link
                                            to="/invitatii/corporate/c1"
                                            id="invitatie-corporate-1"
                                            className={`dropdown-item ${classes["animated-item"]}`}
                                            target="_blank"
                                        >
                                            Clasic
                                        </Link>
                                        {/* <hr className="dropdown-divider" />
                                        <Link
                                            to="/invitatii/nunta/n1"
                                            id="invitatie-nunta-2"
                                            className={`dropdown-item ${classes["animated-item"]}`}
                                            target="_blank"
                                        >
                                            Rustic
                                        </Link> */}
                                    </div>
                                </li>
                            </ul>
                        </li>
                        {props.isLoggedIn && (
                            <li
                                className={`nav-item ${classes["animated-item"]}`}
                                key="invitatiile-mele"
                                onClick={closeNavBar}
                            >
                                <Link
                                    to="/invitatiile-mele"
                                    id="invitatiile-mele"
                                    className="invitatiile-mele"
                                >
                                    Invitațiile mele
                                </Link>
                            </li>
                        )}
                        {props.isAdmin && (
                            <li
                            className={`nav-item ${classes["animated-item"]}`}
                            key="admin"
                            onClick={closeNavBar}
                        >
                            <Link
                                to="/admin"
                                id="admin"
                                className="admin"
                            >
                                Admin
                            </Link>
                        </li>
                        )}
                        {!props.isLoggedIn && (
                            <li
                                className={`nav-item ${classes["animated-item"]}`}
                                key="sing-in"
                                onClick={closeNavBar}
                            >
                                <Link
                                    id="sing-in"
                                    className="sing-in"
                                    onClick={openModal}
                                >
                                    Intră în cont
                                </Link>
                            </li>
                        )}
                        {props.isLoggedIn && (
                            <li
                                className={`nav-item ${classes["animated-item"]}`}
                                key="logout"
                                onClick={closeNavBar}
                            >
                                <Link
                                    to="/"
                                    id="logout"
                                    className="login"
                                    onClick={logoutHandler}
                                >
                                    Deloghează-te
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </nav>
        </div>
    );
};

export default Navigation;
