import classes from './Tabele.module.scss';

import { menus } from '../../../../../Helpers/Helpers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

/**
 * Ascunde sau nu coloana
 * @param {*} answers 
 * @param {*} column 
 * @returns boolean
 */
export const shouldShowColumn = (answers, column) => {
    return !!answers.find((element) => {
        return element[column] !== false;
    });
}
/**
 * Calculează număr total de participanți, meniuri și cazări
 * @param {*} answers
 * @param {*} column
 * @param {*} type
 * @returns number
 */
export const calculateTotal = (answers, column, type) => {
    const counts = [];
    answers.forEach(answer => {
        if (type === 'participant') {
            counts.push(answer[column] || 0);
        } else if (typeof type === 'number') {
            counts.push(menus(answer[column])[type] || 0);
        } else {
            counts.push(answer[column] === 'Da' ? 1 : 0);
        }
    });
    return counts.reduce((total, num) => { return total + num });
}

const TabelNunta = (props) => {
    const { answers } = props,
        showCopii = shouldShowColumn(answers, 'copii'),
        showCazare = shouldShowColumn(answers, 'cazare'),
        showObservatii = shouldShowColumn(answers, 'observatii');
    return (
        <div className={classes['table-wrapper']}>
            <TableContainer className={classes.table} component={Paper} sx={{ maxHeight: 'inherit' }}>
                <Table
                    stickyHeader
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    size="small"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Nume și prenume</TableCell>
                            <TableCell align="center">Participă</TableCell>
                            <TableCell align="center">Număr adulți</TableCell>
                            <TableCell align="center">Meniuri normale adulți</TableCell>
                            <TableCell align="center">Meniuri vegetariene adulți</TableCell>
                            {showCopii && (
                                <>
                                    <TableCell align="center">Număr copii</TableCell>
                                    <TableCell align="center">Meniuri normale copii</TableCell>
                                    <TableCell align="center">Meniuri vegetariene copii</TableCell>
                                    <TableCell align="center">Meniuri normale adulți (copii)</TableCell>
                                    <TableCell align="center">Meniuri vegetariene adulți (copii)</TableCell>
                                    <TableCell align="center">Copii fără meniu</TableCell>
                                </>
                            )}
                            {showCazare && <TableCell align="center">Cazare</TableCell>}
                            {showObservatii && <TableCell align="center">Observații</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {answers.map((row) => (
                            <TableRow key={row.nume_prenume}>
                                <TableCell>
                                    {row.nume_prenume}
                                </TableCell>
                                <TableCell
                                    align="center">{row.participa}
                                </TableCell>
                                <TableCell
                                    align="center">{row.adulti || '-'}
                                </TableCell>
                                <TableCell
                                    align="center">{menus(row.meniuri_adulti)['1'] || '-'}
                                </TableCell>
                                <TableCell
                                    align="center">{menus(row.meniuri_adulti)['2'] || '-'}
                                </TableCell>
                                {showCopii && (
                                    <>
                                        <TableCell
                                            align="center">{row.copii || '-'}
                                        </TableCell>
                                        <TableCell
                                            align="center">{menus(row.meniuri_copii)['1'] || '-'}
                                        </TableCell>
                                        <TableCell
                                            align="center">{menus(row.meniuri_copii)['2'] || '-'}
                                        </TableCell>
                                        <TableCell
                                            align="center">{menus(row.meniuri_copii)['3'] || '-'}
                                        </TableCell>
                                        <TableCell
                                            align="center">{menus(row.meniuri_copii)['4'] || '-'}
                                        </TableCell>
                                        <TableCell
                                            align="center">{menus(row.meniuri_copii)['5'] || '-'}
                                        </TableCell>
                                    </>
                                )}
                                {showCazare && (
                                    <TableCell
                                        align="center">{row.cazare || '-'}
                                    </TableCell>
                                )}
                                {showObservatii && (
                                    <TableCell
                                        align="center">{row.observatii || '-'}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell align="center">Participă</TableCell>
                            <TableCell align="center">Număr adulți</TableCell>
                            <TableCell align="center">Meniuri normale adulți</TableCell>
                            <TableCell align="center">Meniuri vegetariene adulți</TableCell>
                            {showCopii && (
                                <>
                                    <TableCell align="center">Număr copii</TableCell>
                                    <TableCell align="center">Meniuri normale copii</TableCell>
                                    <TableCell align="center">Meniuri vegetariene copii</TableCell>
                                    <TableCell align="center">Meniuri normale adulți (copii)</TableCell>
                                    <TableCell align="center">Meniuri vegetariene adulți (copii)</TableCell>
                                    <TableCell align="center">Copii fără meniu</TableCell>
                                </>
                            )}
                            {showCazare && <TableCell align="center">Cazare</TableCell>}
                            {showObservatii && <TableCell align="center">Observații</TableCell>}
                        </TableRow>
                        <TableRow>
                                <TableCell>
                                    TOTAL
                                </TableCell>
                                <TableCell
                                    align="center">{calculateTotal(answers, 'participa')}
                                </TableCell>
                                <TableCell
                                    align="center">{calculateTotal(answers, 'adulti', 'participant')}
                                </TableCell>
                                <TableCell
                                    align="center">{calculateTotal(answers, 'meniuri_adulti', 1)}
                                </TableCell>
                                <TableCell
                                    align="center">{calculateTotal(answers, 'meniuri_adulti', 2)}
                                </TableCell>
                                {showCopii && (
                                    <>
                                        <TableCell
                                            align="center">{calculateTotal(answers, 'copii', 'participant')}
                                        </TableCell>
                                        <TableCell
                                            align="center">{calculateTotal(answers, 'meniuri_copii', 1)}
                                        </TableCell>
                                        <TableCell
                                            align="center">{calculateTotal(answers, 'meniuri_copii', 2)}
                                        </TableCell>
                                        <TableCell
                                            align="center">{calculateTotal(answers, 'meniuri_copii', 3)}
                                        </TableCell>
                                        <TableCell
                                            align="center">{calculateTotal(answers, 'meniuri_copii', 4)}
                                        </TableCell>
                                        <TableCell
                                            align="center">{calculateTotal(answers, 'meniuri_copii', 5)}
                                        </TableCell>
                                    </>
                                )}
                                {showCazare && (
                                    <TableCell
                                        align="center">{calculateTotal(answers, 'cazare')}
                                    </TableCell>
                                )}
                                {showObservatii && (
                                    <TableCell
                                        align="center">-
                                    </TableCell>
                                )}
                            </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TabelNunta;