import './HomepageCarousel.scss';

import Carousel from 'react-bootstrap/Carousel';
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import carousel_nunta from '../../../Media/Images/Misc/carousel_nunta.png';
import carousel_botez from '../../../Media/Images/Misc/carousel_botez.png';
import carousel_nunta_botez from '../../../Media/Images/Misc/carousel_nunta_botez.png';
import carousel_aniversare from '../../../Media/Images/Misc/carousel_aniversare.png';
import carousel_corporate from '../../../Media/Images/Misc/carousel_corporate.png';

const CTAButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#ffd629',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#e6c532',
    },
  }));

const HomepageCarousel = () => {

return (
    <Carousel fade indicators={window.innerWidth >= 1420}>
        <Carousel.Item>
            <div className="carousel-item-wrapper">
                <div className="slide-text-wrapper">
                    <div className="slide-text">
                        <h5>Se apropie nunta?</h5>
                        <p>Invitația ta online va fi gata în câteva minute.</p>
                        <Link
                            to={`/invitatii/nunta/n1`}
                            target="_blank"
                        >
                            <CTAButton
                                size='large'
                                type='button'
                                variant='contained'
                            >
                                Vreau invitație pentru nuntă
                            </CTAButton>
                        </Link>
                    </div>
                </div>
                <div className="slide-image">
                    <img src={carousel_nunta} alt="..." />
                </div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className="carousel-item-wrapper">
                <div className="slide-text-wrapper">
                    <div className="slide-text">
                        <h5>Se apropie botezul?</h5>
                        <p>Invitația ta online va fi gata în câteva minute.</p>
                        <Link
                            to={`/invitatii/botez/b1`}
                            target="_blank"
                        >
                            <CTAButton
                                size='large'
                                type='button'
                                variant='contained'
                            >
                                Vreau invitație pentru botez
                            </CTAButton>
                        </Link>
                    </div>
                </div>
                <div className="slide-image">
                    <img src={carousel_botez} alt="..." />
                </div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className="carousel-item-wrapper">
                <div className="slide-text-wrapper">
                    <div className="slide-text">
                        <h5>Ai o nuntă și un botez?</h5>
                        <p>Nicio problemă. Invitația ta online va fi gata în câteva minute.</p>
                        <Link
                            to={`/invitatii/nunta-botez/nb1`}
                            target="_blank"
                        >
                            <CTAButton
                                size='large'
                                type='button'
                                variant='contained'
                            >
                                Vreau invitație pentru nuntă și botez
                            </CTAButton>
                        </Link>
                    </div>
                </div>
                <div className="slide-image">
                    <img src={carousel_nunta_botez} alt="..." />
                </div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className="carousel-item-wrapper">
                <div className="slide-text-wrapper">
                    <div className="slide-text">
                        <h5>Ai o aniversare?</h5>
                        <p>
                            Se apropie ziua ta de naștere? Organizezi un grătar?<br />
                            Sau pur și simplu faceți o ieșire cu băieții?<br />
                            Invitația ta online va fi gata în câteva minute.
                        </p>
                        <Link
                            to={`/invitatii/aniversare/a1`}
                            target="_blank"
                        >
                            <CTAButton
                                size='large'
                                type='button'
                                variant='contained'
                            >
                                Vreau invitație pentru aniversare
                            </CTAButton>
                        </Link>
                    </div>
                </div>
                <div className="slide-image">
                    <img src={carousel_aniversare} alt="..." />
                </div>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className="carousel-item-wrapper">
                <div className="slide-text-wrapper">
                    <div className="slide-text">
                        <h5>Team building? Conferință?</h5>
                        <p>
                            Compania ta organizează un team building,<br />
                            o conferință sau un alt tip de eveniment?<br />
                            Invitația ta online va fi gata în câteva minute.
                        </p>
                        <Link
                            to={`/invitatii/corporate/c1`}
                            target="_blank"
                        >
                            <CTAButton
                                size='large'
                                type='button'
                                variant='contained'
                            >
                                Vreau invitație corporate
                            </CTAButton>
                        </Link>
                    </div>
                </div>
                <div className="slide-image">
                    <img src={carousel_corporate} alt="..." />
                </div>
            </div>
        </Carousel.Item>
    </Carousel>
    );
}

export default HomepageCarousel;