import React from "react";

const Invitatii = () => {
    return (
        <div className='page-wrapper'>
            <div className='page-content'>
                <h1>Invitații</h1>
                <p>Aici va fi cândva pagina generală cu invitații</p>
            </div>
        </div>
    );
};

export default Invitatii;
