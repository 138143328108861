import classes from './FooterDropdown.module.scss';

import { useState } from "react";
import { Link as AnchorLink } from "@mui/material";
import { Link } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const FooterDropdown = (props) => {
    const { nume, type, items } = props,
        theme = useTheme(),
        isSmallScreen = useMediaQuery(theme.breakpoints.down("md")),
        [anchor, setAnchor] = useState(null),
        invitationTypes = ['Clasic'],
        open = !!anchor,
        handleClick = (event) => {
            const html = document.getElementsByTagName( 'html' )[0];
            html.setAttribute( 'class', 'stop-scroll' );
            setAnchor(event.currentTarget);
        },
        handleClose = () => {
            const html = document.getElementsByTagName( 'html' )[0];
            html.removeAttribute( 'class', 'stop-scroll' );
            setAnchor(null);
        };

    return (
        <>
            <AnchorLink
                id={`invitatie-${type}`}
                aria-controls={open ? `invitatie-${type}` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {nume}
            </AnchorLink>
            <Menu
                className={classes.menu}
                modal='false'
                aria-labelledby={`invitatie-${type}`}
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: isSmallScreen ? 'bottom' : 'top',
                    horizontal: isSmallScreen ? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {
                    items.map((item, index) => {
                        return (
                            <MenuItem
                                id={`${type}-${item}`}
                                key={`${type}-${item}`}
                                onClick={handleClose}
                            >
                                <Link
                                    to={`/invitatii/${type}/${item}`}
                                    target="_blank"
                                >
                                    {invitationTypes[index]}
                                </Link>
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </>
    );
}

export default FooterDropdown;