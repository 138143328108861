import classes from "./Url.module.scss";

import { useState } from "react";
import { useAuth, getUserProfile } from "../../../../firebase-auth";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../store/modal-slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../Components/UI/AlertMessage/AlertMessage";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "@mui/material";
import {
    saveOrUpdateInvitationData,
    checkIfUrlExists,
} from "../../../../firebase-invitatii";
import { errorHandler } from "../../../../Helpers/Helpers";
import Cookies from "js-cookie";
import Pachete from "../Pachete/Pachete";

const Url = (props) => {
    const { data, type } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [alert, setAlert] = useState({});
    const [checkedUrl, setCheckedUrl] = useState("");
    const [available, setAvailable] = useState(false);

    const dispatch = useDispatch();
    const userData = useAuth();

    const urlTaken = async () => {
        setIsSaving(true);
        return await checkIfUrlExists(checkedUrl)
            .then((result) => {
                setIsSaving(false);
                return result;
            })
            .catch((error) => {
                setIsSaving(false);
                setAlertError(true);
                setAlert(errorHandler(error));
            });
    };

    const urlChangeHandler = (event) => {
        setCheckedUrl(event.target.value);
        formik.setFieldValue(
            "url",
            event.target.value.toLowerCase().replace(" ", ""),
            !!formik.touched.url
        );
        setAvailable(false);
    };

    const checkUrlAvailabilityHandler = async () => {
        const isUrlTaken = await urlTaken();
        setError(isUrlTaken);
        // making sure that checkedUrl is not an empty string
        // before checking the URL availability
        setAvailable(!checkedUrl ? false : !isUrlTaken);
    };

    const switchToPachete = () => {
        Cookies.set("checkout_step", "pachete", { expires: 1 / 24 });
        dispatch(modalActions.close());
        openModalPachete();
    };

    const openModalPachete = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Alege pachetul dorit",
                modalContent:
                    <Pachete
                        newInv={true}
                        data={data}
                        type={type}
                    />,
            })
        );
    };

    const formik = useFormik({
        initialValues: {
            url: "",
        },
        validationSchema: Yup.object().shape({
            url: Yup.string()
                .required("Caută un link care ți se potrivește!")
                .matches(
                    /^[a-z0-9_-]*$/,
                    "Sunt acceptate doar caractere alfa-numerice, '_' și '-'."
                ),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            const profile = await getUserProfile(userData);
            if (available) {
                setIsSaving(true);
                Cookies.set("checkedUrl", JSON.stringify(checkedUrl), { expires: 1 / 24 });
                await saveOrUpdateInvitationData(profile.uid, type, checkedUrl, data, true)
                    .then(() => {
                        setSuccess(true);
                        setIsSaving(false);
                        setAlert({
                            severity: "success",
                            title: "Link salvat",
                            description: "Linkul a fost salvat. Vă redirecționăm la pasul următor...",
                        });
                        setTimeout(() => {
                            setSuccess(false);
                            setAlert({});
                            switchToPachete();
                        }, 750);
                    })
                    .catch((error) => {
                        setAlertError(true);
                        setIsSaving(false);
                        setAlert(errorHandler(error));
                    });
            }
        },
    });

    return (
        <div className={classes["form-wrapper"]}>
            {isSaving && <Spinner text='Un moment...' />}
            {alertError && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {!isSaving && !success && !alertError && (
                <form onSubmit={formik.handleSubmit}>
                    {props.isPending && (
                        <p>
                            <strong>
                                Se pare că nu ai finalizat invitația anterioară. Hai să o facem acum.
                            </strong>
                        </p>
                    )}
                    <p className={classes.intro}>
                        Aici poți personaliza terminația link-ului astfel încât
                        să te reprezinte pe tine și evenimentul organizat
                        (recomandăm să alegi ceva cât mai scurt pentru a fi ușor
                        de reținut).
                    </p>
                    <div className={classes["check-wrapper"]}>
                        <FormControl fullWidth component='fieldset'>
                            <TextField
                                label='Selectează URL-ul dorit'
                                id='selecteazaUrl'
                                placeholder='ana-si-ion (exemplu)'
                                value={formik.values.url}
                                autoComplete='off'
                                error={!!formik.errors.url || error}
                                helperText={
                                    formik.errors.url ||
                                    (error &&
                                        "Acest link nu este disponibil. Încearcă o altă variantă.")
                                }
                                // helperText={
                                //     !!formik.errors.url &&
                                //     "Acest link nu este disponibil. Încearcă o altă variantă."
                                // }
                                onChange={urlChangeHandler}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            https://happy-events.ro/i/
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <div id={classes["check-button"]}>
                            <Button
                                variant='contained'
                                onClick={checkUrlAvailabilityHandler}
                            >
                                Verifică
                            </Button>
                        </div>
                    </div>
                    {available && (
                        <div className={classes["submit-wrapper"]}>
                            <p className={classes.intro}>
                                Acest link este disponibil. Apasă butonul de mai
                                jos pentru a continua.
                            </p>
                            <Button
                                type='submit'
                                variant='contained'
                                id={classes["check-button"]}
                            >
                                Mai departe
                            </Button>
                        </div>
                    )}
                </form>
            )}
        </div>
    );
};

export default Url;
