import classes from '../SettingsMenu.module.scss';

import { useContext } from "react";
import InvitatiileMeleContext from '../../../../../../context/invitatiile-mele-context';
import { useDispatch } from "react-redux";
import { modalActions } from '../../../../../../store/modal-slice';
import AlertMessage from '../../../../../../Components/UI/AlertMessage/AlertMessage';
import MenuItem from '@mui/material/MenuItem';

const CopiazaLinkul = () => {
    const ctx = useContext(InvitatiileMeleContext);
    const dispatch = useDispatch();
    const handleCopyLink = () => {
        const url = window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.hostname === 'localhost' ? ':3000' : '') +
            '/i/' +
            ctx.selectedUrl;
        navigator.clipboard.writeText(url);
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Copiat",
                modalContent: <AlertMessage
                    severity='success'
                    description='Linkul a fost copiat'
                />,
            })
        );
        setTimeout(
            () => {
                dispatch(modalActions.close());
            }, 750
        );
    }
    return (
        <MenuItem
            className={classes['menu-item']}
            onClick={handleCopyLink}
        >
            <i className={`fas fa-link ${classes.icon}`}></i> Copiază linkul invitației
        </MenuItem>
    );
}

export default CopiazaLinkul;