import classes from "./LocationBlock.module.scss";

import Indicatii from "./Indicatii";

const LocationBlock = (props) => {
    let icon;
    if (props.icon === "civil") {
        icon = <i className='fas fa-file-signature'></i>;
    } else if (props.icon === "biserica") {
        icon = <i className='fas fa-church'></i>;
    } else if (props.icon === "petrecere") {
        icon = <i className='fas fa-glass-cheers'></i>;
    }

    return (
        <div className={classes["location-wrapper"]}>
            <div className={classes["icon-wrapper"]}>
                <div className={`${classes.icon} ${classes[props.color]}`}>
                    {icon}
                </div>
            </div>
            <div className={`${classes.location} ${classes[props.color]}`}>
                {!props.hideTitle && <h4>{props.title}</h4>}
                <h4>
                    <strong>{props.location}</strong>
                </h4>
                <p>
                    <i>{props.address}</i>
                </p>
                <p>
                    <strong>Ora {props.hour}</strong>
                </p>
                <Indicatii goTo={props.locationUrl} />
            </div>
        </div>
    );
};

export default LocationBlock;
