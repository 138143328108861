import { Link } from "react-router-dom";
import HomepageCarousel from "./HomepageCarousel/HomepageCarousel";
import Ce from './Ce/Ce';

const Home = () => {
    return (
        <>
            <HomepageCarousel />
            <div className='page-wrapper'>
                <div className='page-content'>
                    <Ce />
                    <h1>This is the home page</h1>
                    <br />
                    <Link to='about'>Click to view our about page</Link>
                    <br />
                    <br />
                    <Link to='contact'>Click to view our contact page</Link>
                    <br />
                    <br />
                    <Link to='invitatii/nunta/n1'>Invitație Nuntă 1</Link>
                    <br />
                    <br />
                    <Link to='invitatii/botez/b1'>Invitație Botez 1</Link>
                    <br />
                    <br />
                    <Link to='invitatii/nunta-botez/nb1'>Invitație Nuntă și Botez 1</Link>
                    <br />
                    <br />
                    <Link to='invitatii/aniversare/a1'>Invitație Aniversare 1</Link>
                    <br />
                    <br />
                    <Link to='invitatii/corporate/c1'>Invitație Corporate 1</Link>
                    <br />
                    <br />
                    <Link to='about'>Click to view our about page</Link>
                    <br />
                    <br />
                    <Link to='contact'>Click to view our contact page</Link>
                    <br />
                    <br />
                    <Link to='invitatii/nunta/n1'>Invitație Nuntă 1</Link>
                    <br />
                    <br />
                    <Link to='invitatii/botez/b1'>Invitație Botez 1</Link>
                    <br />
                    <br />
                    <Link to='about'>Click to view our about page</Link>
                    <br />
                    <br />
                    <Link to='contact'>Click to view our contact page</Link>
                    <br />
                    <br />
                    <Link to='invitatii/nunta/n1'>Invitație Nuntă 1</Link>
                    <br />
                    <br />
                    <Link to='invitatii/botez/b1'>Invitație Botez 1</Link>
                    <br />
                    <br />
                    <Link to='about'>Click to view our about page</Link>
                    <br />
                    <br />
                    <Link to='contact'>Click to view our contact page</Link>
                    <br />
                    <br />
                    <Link to='invitatii/nunta/n1'>Invitație Nuntă 1</Link>
                    <br />
                    <br />
                    <Link to='invitatii/botez/b1'>Invitație Botez 1</Link>
                </div>
            </div>
        </>
    );
};

export default Home;
