import classes from '../SettingsMenu.module.scss';

import { useContext, useState, useEffect } from "react";
import InvitatiileMeleContext from '../../../../../../context/invitatiile-mele-context';
import { useDispatch } from "react-redux";
import { modalActions } from '../../../../../../store/modal-slice';
import MenuItem from '@mui/material/MenuItem';
import Pachete from '../../../../../Invitatii/Components/Pachete/Pachete';
import { fetchInvitationData } from '../../../../../../firebase-invitatii';
import { errorHandler } from '../../../../../../Helpers/Helpers';
import AlertMessage from '../../../../../../Components/UI/AlertMessage/AlertMessage';

const CumparaRaspunsuri = () => {
    const [invData, setInvData] = useState(null);
    const [alert, setAlert] = useState({});
    const [alertError, setAlertError] = useState(false);
    const ctx = useContext(InvitatiileMeleContext);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!invData) {
            const invitationData = async () => await fetchInvitationData(
                ctx.selected.iid,
                ctx.selected.invitationType,
                ctx.uid
            )
                .then((response) => {
                    setInvData(response);
                    return response;
                })
                .catch((err) => {
                    setAlertError(true);
                    setAlert(errorHandler(err));
                });
            invitationData();
        }
    }, [ctx.selected.iid, ctx.selected.invitationType, ctx.uid, invData]);
    const handleCumparaRaspunsuri = async () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: `${alertError ? "A apărut o eroare!" : "Alege pachetul dorit"}`,
                modalContent: (
                    alertError ?
                    <AlertMessage
                        severity={alert.severity}
                        title={alert.title}
                        description={alert.description}
                        refresh={alert.refresh}
                    /> :
                    <Pachete
                        data={invData}
                        iid={ctx.selected.iid}
                        type={ctx.selected.invitationType}
                    />
                ),
            })
        );
    }

    return (
        <MenuItem
            className={classes['menu-item']}
            onClick={handleCumparaRaspunsuri}
        >
            <i className={`fas fa-dollar-sign ${classes.icon}`}></i> Cumpără răspunsuri
        </MenuItem>
    );
}

export default CumparaRaspunsuri;