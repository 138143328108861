import classes from "../../../Form1.module.scss";
import styles from "../../../FormSlide2.module.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import TextInput from "../../../../../Components/UI/Form/TextInput";
import TimePickerInput from "../../../../../Components/UI/Form/TimePickerInput";
import Spinner from "../../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../../Components/UI/AlertMessage/AlertMessage";
import LocationAutocomplete from "../../../Components/LocationAutocomplete/LocationAutocomplete";
import ImageRadios from "../../../Components/ImageRadios/ImageRadios";
import { saveOrUpdateInvitationData } from "../../../../../firebase-invitatii";
import { errorHandler } from "../../../../../Helpers/Helpers";
import Cookies from "js-cookie";

const FormSlide2 = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState({});

    const dispatch = useDispatch();

    const locallySaveSlide2 = (labelName, dataSlide2, hoursToExpire) => {
        Cookies.set(labelName, JSON.stringify(dataSlide2), { expires: 1 / 24 * hoursToExpire });
    };

    // ANIVERSARE
    const onDenumireLocatieAniversareChange = (
        denumire_locatie_cununie_civila
    ) => {
        formik.setFieldValue(
            "denumireLocatieAniversare",
            denumire_locatie_cununie_civila,
            !!formik.touched.denumireLocatieAniversare
        );
    };

    const onAdresaAniversareChange = (adresa_cununie_civila) => {
        formik.setFieldValue(
            "adresaAniversare",
            adresa_cununie_civila,
            !!formik.touched.adresaAniversare
        );
    };

    const onOraAniversareChange = (ora_cununie_civila) => {
        formik.setFieldValue(
            "oraAniversare",
            ora_cununie_civila,
            !!formik.touched.oraAniversare
        );
    };

    const onLocatieAniversareChange = (data) => {
        formik.setFieldValue(
            "locatieAniversare",
            data.description,
            !!formik.touched.locatieAniversare
        );
        if (data.description) {
            formik.setFieldValue(
                "denumireLocatieAniversare",
                data.description?.split(",")["0"],
                !!formik.touched.locatieAniversare
            );
            formik.setFieldValue(
                "adresaAniversare",
                data.description?.replace(
                    `${data.description?.split(",")["0"]},`,
                    ""
                ),
                !!formik.touched.locatieAniversare
            );
            formik.setFieldValue(
                "urlAniversare",
                `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${data.place_id}`,
                !!formik.touched.locatieAniversare
            );
        }
    };

    const onColorChange = (color) => {
        formik.setFieldValue("color", color, !!formik.touched.color);
    };

    const invitatiileMeleHandler = () => {
        window.location.pathname = '/invitatiile-mele';
    }

    const formik = useFormik({
        initialValues: {
            aniversare: props.slideData.aniversare,
            locatieAniversare: props.slideData.locatieAniversare,
            denumireLocatieAniversare:
                props.slideData.denumireLocatieAniversare,
            adresaAniversare: props.slideData.adresaAniversare,
            oraAniversare: dayjs(props.slideData.oraAniversare),
            urlAniversare: props.slideData.urlAniversare,
            cununieReligioasa: props.slideData.cununieReligioasa,
            locatieCununieReligioasa: props.slideData.locatieCununieReligioasa,
            denumireLocatieCununieReligioasa:
                props.slideData.denumireLocatieCununieReligioasa,
            adresaCununieReligioasa: props.slideData.adresaCununieReligioasa,
            oraCununieReligioasa: dayjs(props.slideData.oraCununieReligioasa),
            urlCununieReligioasa: props.slideData.urlCununieReligioasa,
            petrecere: props.slideData.petrecere,
            locatiePetrecere: props.slideData.locatiePetrecere,
            denumireLocatiePetrecere: props.slideData.denumireLocatiePetrecere,
            adresaPetrecere: props.slideData.adresaPetrecere,
            oraPetrecere: dayjs(props.slideData.oraPetrecere),
            urlPetrecere: props.slideData.urlPetrecere,
            color: props.slideData.color,
        },
        validationSchema: Yup.object()
            .shape({
                aniversare: Yup.bool(),
                locatieAniversare: Yup.string().required(
                    "Locația unde are loc cununia civilă este oblicatorie!"
                ),
                denumireLocatieAniversare: Yup.string().required(
                    "Denumirea locației unde are loc cununia civilă este oblicatorie!"
                ),
                adresaAniversare: Yup.string().required(
                    "Adresa locației unde are loc cununia civilă este oblicatorie!"
                ),
                oraAniversare: Yup.string().required(
                    "Ora la care are loc cununia civilă este oblicatorie!"
                ),
            }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            setIsSaving(true);
            const slide2Data = {
                aniversare: values.aniversare,
                locatieAniversare: values.locatieAniversare,
                denumireLocatieAniversare:
                    values.denumireLocatieAniversare,
                adresaAniversare: values.adresaAniversare,
                oraAniversare: values.oraAniversare,
                urlAniversare: values.urlAniversare,
                color: values.color,
            };
            props.setSlideData(slide2Data);
            if (props.editMode) {
                const invData = {
                    c1s1: JSON.parse(Cookies.get(`${props.invId}_c1s1`)),
                    c1s2: JSON.parse(Cookies.get(`${props.invId}_c1s2`)),
                    c1s3: JSON.parse(Cookies.get(`${props.invId}_c1s3`)),
                };
                locallySaveSlide2(`${invData.c1s1.iid}_c1s2`, slide2Data, 1);
                invData.c1s2 = JSON.parse(Cookies.get(`${invData.c1s1.iid}_c1s2`));
                await saveOrUpdateInvitationData(
                    props.uid,
                    'c1',
                    false,
                    invData,
                    false,
                    props.invTitle
                    )
                    .then(() => {
                        setIsSaving(false);
                        setAlert({
                            severity: "success",
                            title: "Modificări salvate",
                            description: "Modificările au fost salvate cu succes!",
                        });
                        setSuccess(true);
                    })
                    .catch((error) => {
                        setError(true);
                        setIsSaving(false);
                        setAlert(errorHandler(error));
                    });
            } else {
                locallySaveSlide2('c1s2', slide2Data, 24);
                setIsSaving(false);
                props.slideUpdated(true);
                setAlert({
                    severity: "success",
                    title: "Modificări salvate",
                    description: `Modificările au fost salvate cu succes!`,
                });
                setSuccess(true);
                setTimeout(() => {
                    dispatch(modalActions.close());
                }, 750);
            }
        },
    });

    return (
        <div className={`${classes["form-wrapper"]} ${styles["form-slide-2"]}`}>
            {isSaving && <Spinner text='Salvare...' />}
            {error && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <>
                    <AlertMessage
                        severity={alert.severity}
                        title={alert.title}
                        description={alert.description}
                        refresh={alert.refresh}
                    />
                    {props.editMode && (
                        <Button
                            id={classes.submitButton}
                            type='button'
                            variant='contained'
                            onClick={invitatiileMeleHandler}
                        >
                            Înapoi la "Invitațiile mele"
                        </Button>
                    )}
                </>
            )}
            {!isSaving && !success && !error && (
                <form onSubmit={formik.handleSubmit}>
                    <p>
                        Alege locația în care va avea loc evenimentul:
                    </p>
                    {/* ANIVERSARE */}
                        <div className={styles["location-inputs"]}>
                            <LocationAutocomplete
                                label='Caută locație'
                                noLocation='Nicio locație'
                                value={formik.values.locatieAniversare}
                                changeHandler={onLocatieAniversareChange}
                                error={formik.errors.locatieAniversare}
                            />
                            <TextInput
                                label='Denumire locație'
                                value={
                                    formik.values.denumireLocatieAniversare
                                }
                                placeholder='Casa Căsătoriilor'
                                changeHandler={
                                    onDenumireLocatieAniversareChange
                                }
                                error={
                                    formik.errors.denumireLocatieAniversare
                                }
                            />
                            <TextInput
                                label='Adresa'
                                value={formik.values.adresaAniversare}
                                placeholder='Bulevardul Mihai Eminescu 15, Timișoara'
                                changeHandler={onAdresaAniversareChange}
                                error={formik.errors.adresaAniversare}
                            />
                            <TimePickerInput
                                label='Ora'
                                value={dayjs(formik.values.oraAniversare)}
                                changeHandler={onOraAniversareChange}
                                error={formik.errors.oraAniversare}
                            />
                        </div>
                    <hr className={styles.hr} />
                    <ImageRadios
                        default={props.slideData.color}
                        text='Culoarea chenarelor:'
                        labels={[
                            "darkorange",
                            "deeppink",
                            "dodgerblue",
                            "gold",
                            "limegreen",
                            "maroon",
                            "mediumturquoise",
                            "burlywood",
                            "red",
                            "violet",
                        ]}
                        path='Nunta'
                        changeHandler={onColorChange}
                    />
                    {formik.errors.minOneLocation && (
                        <p className='error-alert'>
                            {formik.errors.minOneLocation}
                        </p>
                    )}
                    <Button type='submit' variant='contained'>
                        Actualizare
                    </Button>
                </form>
            )}
        </div>
    );
};

export default FormSlide2;
