import classes from "./Classes.module.scss";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";

const DatePickerInput = (props) => {
  const dateChangeHandler = (event) => {
    props.changeHandler(Date.parse(event.$d));
  };
  return (
    <FormControl
      fullWidth={!props.notFullWidth}
      component="fieldset"
      className={classes.formFieldWrapper}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className={classes.datepicker}
          label={props.label}
          value={props.value}
          format="DD-MM-YYYY"
          onChange={dateChangeHandler}
          disableFuture={!!props.disableFuture}
          disablePast={!!props.disablePast}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default DatePickerInput;
