import classes from './Refresh.module.scss';

import { useState } from 'react';
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import IconButton from '@mui/material/IconButton';

const Refresh = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const fetchDataHandler = () => {
        setIsLoading(true);
        props.fetchData();
        setTimeout(() => { setIsLoading(false) }, 350);
    }
    return (
        <Tooltip TransitionComponent={Zoom} title="Click pentru a reîmprospăta lista de răspunsuri" arrow>
            <IconButton
                id={classes.refreshButton}
                onClick={fetchDataHandler}
                style={{ maxWidth: "40px", minWidth: "40px" }}
                aria-label="Reîmprospătare listă de răspunsuri"
            >
                <i className={`fas fa-sync-alt ${isLoading && classes.spin}`}></i>
            </IconButton>
        </Tooltip>
    );
}

export default Refresh;