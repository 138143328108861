import classes from './DeleteModal.module.scss';

import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../../store/modal-slice";
import { deleteInvitation } from "../../../../../../firebase-admin";
import { errorHandler } from "../../../../../../Helpers/Helpers";
import { Button } from "@mui/material";
import Spinner from '../../../../../UI/Spinner/Spinner';
import AlertMessage from '../../../../../UI/AlertMessage/AlertMessage';

const DeleteModal = (props) => {
    const [error, setError] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alert, setAlert] = useState({});
    const dispatch = useDispatch();

    const deleteInvitationId = () => {
        setIsDeleting(true);
        deleteInvitation(props.iid)
            .then((res) => {
                setAlert({
                    severity: "success",
                    title: "S-a șters!",
                    description: <div>
                                    <span>
                                        {"Invitația a fost ștearsă cu succes!"}
                                    </span><br />
                                    <span>
                                        {`Referința ${res.other_urls ? '' : 'NU'} a fost ștearsă din tabelul "other/urls".`}
                                    </span><br />
                                    <span>
                                        {`Referința ${res.users_uid_invitatii ? '' : 'NU'} a fost ștearsă din tabelul "users/UID/invitatii".`}
                                    </span><br />
                                    <span>
                                        {`Referința ${res.users_uid_invitesLeft ? '' : 'NU'} a fost ștearsă din tabelul "users/UID/invites_left".`}
                                    </span><br />
                                    <span>
                                        {`Referința ${res.users_uid_raspunsuri ? '' : 'NU'} a fost ștearsă din tabelul "users/UID/raspunsuri".`}
                                    </span>
                                </div>,
                });
                setSuccess(true);
                props.handleData(null);
            })
            .catch((error) => {
                setAlert(errorHandler(error));
                setSuccess(false);
                setError(true);
            })
            .finally(() => {
                setIsDeleting(false);
                setTimeout(() => {
                    setSuccess(false);
                    setAlert({});
                    dispatch(modalActions.close());
                }, 5000);
            });
    }

    return (
        <div className={classes['delete-modal']}>
            {isDeleting && <Spinner text="Se șterge..." />}
            {error && (
                <AlertMessage
                severity={alert.severity}
                title={alert.title}
                description={alert.description}
                refresh={alert.refresh}
                />
            )}
            {success && (
                <AlertMessage
                severity={alert.severity}
                title={alert.title}
                description={alert.description}
                refresh={alert.refresh}
                />
            )}
            {!isDeleting && !success && !error && (
                <>
                    <p>Ești sigur că vrei sa ștergi invitația cu ID-ul<br /> {props.iid}?</p>
                    <Button
                        className={classes['action-button']}
                        type="button"
                        variant='contained'
                        onClick={deleteInvitationId}
                    >
                        Da
                    </Button>
                    <Button
                        className={classes['action-button']}
                        type="button"
                        variant='contained'
                        onClick={() => dispatch(modalActions.close())}
                    >
                        Nu
                    </Button>
                </>
            )}
        </div>
    );
}

export default DeleteModal;