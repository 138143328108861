import classes from './ListaOptiuni.module.scss';

import { useState, useContext } from "react";
import AdminContext from "../../../context/admin-context";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const ListaOptiuni = () => {
    const ctx = useContext(AdminContext);
    // const [value, setValue] = useState(ctx.data.length === 1 ? 0 : false);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        ctx.handleSelectedTab(newValue);
    };
    return (
        <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Listă opțiuni"
            className={classes.tabs}
        >
            <Tab
                id="cauta_invitatii"
                key="cauta_invitatii"
                label="Caută invitații"
            />
            <Tab
                id="creaza_admin"
                key="creaza_admin"
                label="Crează admin"
            />
        </Tabs>
    );
}

export default ListaOptiuni;