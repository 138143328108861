import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../store/modal-slice";
import Login from "./Login/Login";
import SignUp from "./SignUp/SignUp";
import ForgotPassword from "./ForgotPassword/ForgotPassword";

const Auth = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState("login");

  const switchModal = (state) => {
    setShow(state);
    state === "login" &&
      dispatch(modalActions.modal({ modalTitle: "Intră în cont" }));
    state === "sign-up" &&
      dispatch(modalActions.modal({ modalTitle: "Crează-ți cont" }));
    state === "forgot-password" &&
      dispatch(modalActions.modal({ modalTitle: "Resetare parolă" }));
  };

  const closeModal = () => {
    dispatch(modalActions.close());
  };

  return (
    <>
      {show === "login"
        && <Login
            show={switchModal}
            close={closeModal}
            fromInvitation={props.fromInvitation}
            data={props.data}
            invitationType={props.invitationType}
            openModalUrl={props.openModalUrl}
        />}
      {show === "sign-up"
          && <SignUp
              show={switchModal}
              fromInvitation={props.fromInvitation}
              data={props.data}
              invitationType={props.invitationType}
          />}
      {show === "forgot-password" && <ForgotPassword show={switchModal} />}
    </>
  );
};

export default Auth;
