import classes from "../../../Slides1.module.scss";

import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import EditInvitatie from "../../../Components/Edit/Edit";
import FormSlide3 from "./FormSlide3";
import FormNunta from "../../../Components/FormNunta/FormNunta";
import Cookies from "js-cookie";

const Slide3 = (props) => {
    const videoRef = useRef();
    const [initialRender, setInitialRender] = useState(true);
    const [slide3Data, setSlide3Data] = useState({
        image: {
            value: "n1s3_1",
            url: "/Media/Videos/Modele/Nunta/n1s3_1",
        },
        copii: true,
        cazare: true,
        observatii: true,
    });

    const savedSlide1Data = props.invId
        ? Cookies.get(`${props.invId}_n1s1`)
        : Cookies.get("n1s1");

    const savedSlide2Data = props.invId
        ? Cookies.get(`${props.invId}_n1s2`)
        : Cookies.get("n1s2");

    const savedSlide3Data = props.invId
        ? Cookies.get(`${props.invId}_n1s3`)
        : Cookies.get("n1s3");

    useEffect(() => {
        if (initialRender && !!savedSlide3Data) {
            setSlide3Data(JSON.parse(savedSlide3Data));
            setInitialRender(false);
        }
    }, [initialRender, savedSlide3Data]);

    useEffect(() => {
        videoRef.current?.load();
    }, [slide3Data.video]);

    const dispatch = useDispatch();

    const openModalSlide3 = () => {
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Editează a treia pagină",
                modalContent: (
                    <FormSlide3
                        uid={props.uid}
                        invId={props.invId}
                        invTitle={props.invTitle}
                        slideData={slide3Data}
                        setSlideData={setSlide3Data}
                        editMode={props.editMode}
                    />
                ),
            })
        );
    };

    return (
        <div id={classes.slide_3}>
            {!props.live && (
                <EditInvitatie
                    tooltipText='Editează a treia pagină'
                    openModal={openModalSlide3}
                    slide={3}
                />
            )}
            <div className={`${classes.text} ${classes['full-height']}`}>
                <FormNunta
                    slideData={slide3Data}
                    urlData={props.urlData}
                    live={props.live}
                    slide1Data={savedSlide1Data && JSON.parse(savedSlide1Data)}
                    slide2Data={savedSlide2Data && JSON.parse(savedSlide2Data)}
                />
            </div>
            <div className={classes.video}>
                <video
                    autoPlay
                    muted
                    playsInline
                    loop
                    key={slide3Data.image.value}
                >
                    <source
                        src={require(`../../../../../Media/Videos/Modele/Nunta/${slide3Data.image.value}.mov`)}
                        type='video/mp4'
                    />
                    Your browser does not support HTML5 video.
                </video>
            </div>
        </div>
    );
};

export default Slide3;
