import classes from './Ce.module.scss';

import Paper from '@mui/material/Paper';
import FadeAnimation from '../../../Components/UI/FadeAnimation/FadeAnimation';
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import smartphonetemplate from '../../../Media/Images/Misc/smartphone_template.png';
import smartphone_screen from '../../../Media/Images/Misc/smartphone_screen.jpg';

const Ce = () => {
    return (
        <>
            <h1>Ce?</h1>
            <p>Iată mai jos câteva dintre avantajele unei invitații 100% online.</p>
            <div className={classes['ce-wrapper']}>
                <div className={classes['ce-left']}>
                    <FadeAnimation animation='fade-from-left'>
                        <Paper elevation={2} className={classes.paper}>
                            <div>
                                <h5>Invitația este gata în câteva minute</h5>
                                <p>În câteva minute invitația este gata și poate fi trimisă invitaților</p>
                            </div>
                        </Paper>
                    </FadeAnimation>
                    <FadeAnimation animation='fade-from-left'>
                        <Paper elevation={2} className={classes.paper}>
                            <div>
                                <h5>Personalizare</h5>
                                <p>Poți personaliza invitația astfel încât să se potrivească tematicii evenimentului tău</p>
                            </div>
                        </Paper>
                    </FadeAnimation>
                    <FadeAnimation animation='fade-from-left'>
                        <Paper elevation={2} className={classes.paper}>
                            <div>
                                <h5>Câștigi timp</h5>
                                <p>Câștigi timp trimițând invitația pe WhatsApp, Facebook, email, etc. evitând astfel să te deplasezi și să înmânezi personal invitațiile</p>
                            </div>
                        </Paper>
                    </FadeAnimation>
                    <FadeAnimation animation='fade-from-left'>
                        <Paper elevation={2} className={classes.paper}>
                            <div>
                                <h5>Editare ulterioară</h5>
                                <p>Dacă ai sesizat o greșeală sau intervine vreo modificare, poți foarte ușor să faci corecturile necesare editând invitația</p>
                            </div>
                        </Paper>
                    </FadeAnimation>
                    <FadeAnimation animation='fade-from-left'>
                        <Paper elevation={2} className={classes.paper}>
                            <div>
                                <h5>Email cu fiecare confirmare</h5>
                                <p>Primești email cu fiecare confirmare și poți vizualiza toate răspunsurile în contul tău în secțiunea ”Invitațiile mele”</p>
                            </div>
                        </Paper>
                    </FadeAnimation>
                    <FadeAnimation animation='fade-from-left'>
                        <Paper elevation={2} className={classes.paper}>
                            <div>
                                <h5>Invitație mereu actualizată</h5>
                                <p>Invitații pot accesa oricând invitația și vor vedea tot timpul versiunea actualizată a ei</p>
                            </div>
                        </Paper>
                    </FadeAnimation>
                    <FadeAnimation animation='fade-from-left'>
                        <Paper elevation={2} className={classes.paper}>
                            <div>
                                <h5>Indicații pentru invitați</h5>
                                <p>Invitații vor ști unde trebuie să ajungă accesând butonul ”Indicații”</p>
                            </div>
                        </Paper>
                    </FadeAnimation>
                    <FadeAnimation animation='fade-from-left'>
                        <Paper elevation={2} className={classes.paper}>
                            <div>
                                <h5>Link personalizat</h5>
                                <p>Link personalizat cu denumirea evenimentului
                                Countdown eveniment</p>
                            </div>
                        </Paper>
                    </FadeAnimation>
                    <FadeAnimation animation='fade-from-left'>
                        <Paper elevation={2} className={classes.paper}>
                            <div>
                                <h5>Protejezi natura</h5>
                                <p>Protejezi natura evitând printarea pe hârtie a invitațiilor</p>
                            </div>
                        </Paper>
                    </FadeAnimation>
                </div>
                <div className={classes['ce-right']}>
                    <div 
                        className={classes.smartphone} 
                        style={{
                            backgroundImage: `url(${smartphonetemplate})`,
                        }}
                    >
                        <div className={classes['smartphone-screen']}>
                            <img src={smartphone_screen} alt='Smartphone screen' />
                        </div>
                    </div>
                    <Link to={`/invitatii`}>
                        <FadeAnimation animation='fade-from-right'>
                            <Button
                                size='large'
                                type='button'
                                variant='contained'
                            >
                                Vreau invitație
                            </Button>
                        </FadeAnimation>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Ce;