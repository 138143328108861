import classes from "../Auth.module.scss";

import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { resetPassword } from "../../../firebase-auth";
import { errorHandler } from "../../../Helpers/Helpers";
import { Button } from "@mui/material";
import Spinner from "../../UI/Spinner/Spinner";
import AlertMessage from "../../UI/AlertMessage/AlertMessage";
import TextInput from "../../UI/Form/TextInput";

const ForgotPassword = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState({});
  const [error, setError] = useState(false);

  const switchToLogin = () => {
    props.show("login");
  };

  const onEmailChange = (email) => {
    formik.setFieldValue("email", email, !!formik.touched.email);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Introduceți o adresă de email validă!")
        .required("Introduceți o adresă de email!"),
    }),
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      setIsSaving(true);
      resetPassword(values.email)
        .then(async () => {
          setAlert({
            severity: "success",
            title: "Success",
            description: `Instrucțiunile pentru resetarea parolei au fost trimise la următoarea adresă de email: '${values.email}'!`,
          });
          setSuccess(true);
        })
        .catch((error) => {
          setAlert(errorHandler(error));
          setSuccess(false);
          setError(true);
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
  });

  return (
    <div className={classes["reset-password"]}>
      {isSaving && <Spinner text="Se trimite..." />}
      {error && (
        <AlertMessage
          severity={alert.severity}
          title={alert.title}
          description={alert.description}
          refresh={alert.refresh}
        />
      )}
      {success && (
        <AlertMessage
          severity={alert.severity}
          title={alert.title}
          description={alert.description}
          refresh={alert.refresh}
        />
      )}
      {!isSaving && !success && !error && (
        <form onSubmit={formik.handleSubmit}>
          <p>Introduceți adresa de email asociată contului la care doriți să resetați parola:</p>
          <TextInput
            label="Email"
            changeHandler={onEmailChange}
            error={formik.errors.email}
          />
          <Button id={classes.submitButton} type="submit" variant="contained">
            Resetează parola
          </Button>
          <p>
            <span className="anchor" onClick={switchToLogin}>
              Înapoi la autentificare!
            </span>
          </p>
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
