import classes from "./ListaInvitatii.module.scss";

import { useState, useContext } from "react";
import InvitatiileMeleContext from "../../../context/invitatiile-mele-context";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const ListaInvitatii = () => {
    const ctx = useContext(InvitatiileMeleContext);
    const [value, setValue] = useState(ctx.data.length === 1 ? 0 : false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Listă invitații"
            className={classes.tabs}
        >
            {[...ctx.data]
                .sort((a, b) => {
                    if (a.title < b.title) {
                        return -1;
                    }
                    if (a.title > b.title) {
                        return 1;
                    }
                    return 0;
                })
                .map((inv) => {
                    return (
                        <Tab
                            id={inv.iid}
                            key={inv.iid}
                            label={inv.title}
                            onClick={() => ctx.selectInvitation(inv)}
                        />
                    );
            })}
        </Tabs>
    );
}

export default ListaInvitatii;
