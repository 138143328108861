import classes from "../../../Form1.module.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../../store/modal-slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import Spinner from "../../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../../Components/UI/AlertMessage/AlertMessage";
import ImageRadios from "../../../Components/ImageRadios/ImageRadios";
import CheckBox from "../../../../../Components/UI/Form/CheckBox";
import { saveOrUpdateInvitationData } from "../../../../../firebase-invitatii";
import { errorHandler } from "../../../../../Helpers/Helpers";
import Cookies from "js-cookie";

const FormSlide3 = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState({});
    const [slideImage, setSlideImage] = useState(props.slideData.image);
    const [showCopii, setShowCopii] = useState(props.slideData.copii);
    const [showCazare, setShowCazare] = useState(props.slideData.cazare);
    const [showObservatii, setShowObservatii] = useState(
        props.slideData.observatii
    );

    const dispatch = useDispatch();

    const locallySaveSlide3 = (labelName, dataSlide3, hoursToExpire) => {
        Cookies.set(labelName, JSON.stringify(dataSlide3), { expires: 1 / 24 * hoursToExpire });
    };

    const onCopiiChange = (copii) => {
        formik.setFieldValue("copii", copii, !!formik.touched.copii);
        setShowCopii(copii);
    };
    const onCazareChange = (cazare) => {
        formik.setFieldValue("cazare", cazare, !!formik.touched.cazare);
        setShowCazare(cazare);
    };
    const onObservatiiChange = (observatii) => {
        formik.setFieldValue(
            "observatii",
            observatii,
            !!formik.touched.observatii
        );
        setShowObservatii(observatii);
    };

    const onVideoChange = (video) => {
        formik.setFieldValue("video", video.value, !!formik.touched.video);
        setSlideImage(video);
    };

    const invitatiileMeleHandler = () => {
        window.location.pathname = '/invitatiile-mele';
    }

    const formik = useFormik({
        initialValues: {
            image: props.slideData.image,
            copii: props.slideData.copii,
            cazare: props.slideData.cazare,
            observatii: props.slideData.observatii,
        },
        validationSchema: Yup.object().shape({
            image: Yup.object(),
            copii: Yup.bool(),
            cazare: Yup.bool(),
            observatii: Yup.bool(),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => {
            setIsSaving(true);
            const slide3Data = {
                image: {
                    value: slideImage.value,
                    url: slideImage.url
                        ? slideImage.url
                        : `/Media/Images/Modele/Nunta/${slideImage.value}`,
                },
                copii: values.copii,
                cazare: values.cazare,
                observatii: values.observatii,
            };
            props.setSlideData(slide3Data);
            if (props.editMode) {
                const invData = {
                    n1s1: JSON.parse(Cookies.get(`${props.invId}_n1s1`)),
                    n1s2: JSON.parse(Cookies.get(`${props.invId}_n1s2`)),
                    n1s3: JSON.parse(Cookies.get(`${props.invId}_n1s3`)),
                };
                locallySaveSlide3(`${invData.n1s1.iid}_n1s3`, slide3Data, 1);
                invData.n1s3 = JSON.parse(Cookies.get(`${invData.n1s1.iid}_n1s3`));
                await saveOrUpdateInvitationData(
                    props.uid,
                    'n1',
                    false,
                    invData,
                    false,
                    props.invTitle
                    )
                    .then(() => {
                        setIsSaving(false);
                        setAlert({
                            severity: "success",
                            title: "Modificări salvate",
                            description: "Modificările au fost salvate cu succes!",
                        });
                        setSuccess(true);
                    })
                    .catch((error) => {
                        setError(true);
                        setIsSaving(false);
                        setAlert(errorHandler(error));
                    });
            } else {
                locallySaveSlide3('n1s3', slide3Data, 24);
                setIsSaving(false);
                setAlert({
                    severity: "success",
                    title: "Modificări salvate",
                    description: `Modificările au fost salvate cu succes!`,
                });
                setSuccess(true);
                setTimeout(() => {
                    dispatch(modalActions.close());
                }, 750);
            }
        },
    });

    return (
        <div className={classes["form-wrapper"]}>
            {isSaving && <Spinner text='Salvare...' />}
            {error && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <>
                    <AlertMessage
                        severity={alert.severity}
                        title={alert.title}
                        description={alert.description}
                        refresh={alert.refresh}
                    />
                    {props.editMode && (
                        <Button
                            id={classes.submitButton}
                            type='button'
                            variant='contained'
                            onClick={invitatiileMeleHandler}
                        >
                            Înapoi la "Invitațiile mele"
                        </Button>
                    )}
                </>
            )}
            {!isSaving && !success && !error && (
                <form onSubmit={formik.handleSubmit}>
                    <p>
                        În funcție de evenimentul dumneavoastră activați sau
                        dezactivați secțiunile de care aveți nevoie.
                    </p>
                    <CheckBox
                        label='Activează / dezactivează secțiunea ”Copii”'
                        value={formik.values.copii}
                        changeHandler={onCopiiChange}
                    />
                    <CheckBox
                        label='Activează / dezactivează secțiunea ”Cazare”'
                        value={formik.values.cazare}
                        changeHandler={onCazareChange}
                    />
                    <CheckBox
                        label='Activează / dezactivează secțiunea ”Observații”'
                        value={formik.values.observatii}
                        changeHandler={onObservatiiChange}
                    />
                    <ImageRadios
                        default={props.slideData.image}
                        text='Video dreapta, vizibil doar pe ecrane mari (PC):'
                        labels={[
                            "n1s3_1",
                            "n1s3_2",
                            "n1s3_3",
                            "n1s3_4",
                            "n1s3_5",
                        ]}
                        path='Nunta'
                        changeHandler={onVideoChange}
                    />
                    <Button
                        id={classes.submitButton}
                        type='submit'
                        variant='contained'
                    >
                        Actualizare
                    </Button>
                </form>
            )}
        </div>
    );
};

export default FormSlide3;
