import classes from "./AlertMessage.module.scss";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Link from "@mui/material/Link";

const AlertMessage = (props) => {
  const refreshHandler = () => {
    window.location.reload();
  };
  return (
    <Alert severity={props.severity} className={classes["alert-message"]}>
      <AlertTitle>
        <strong>{props.title}</strong>
      </AlertTitle>
      {props.description}
      {!!props.refresh && (
        <Link className={classes.refresh} onClick={refreshHandler}>
          {props.refresh}
        </Link>
      )}
    </Alert>
  );
};

export default AlertMessage;
