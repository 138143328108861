import classes from "./LocationAutocomplete.module.scss";

import { useState, useRef, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import FormHelperText from "@mui/material/FormHelperText";

const GOOGLE_MAPS_API_KEY = "AIzaSyB-xJ7lS1ef3_0LS_8TC-f9bKxbaEFO-uY";

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

const LocationAutocomplete = (realProps) => {
    const [value, setValue] = useState(realProps.value);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);

    if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                document.querySelector("head"),
                "google-maps"
            );
        }

        loaded.current = true;
    }

    const fetch = useMemo(
        () =>
            debounce((request, callback) => {
                autocompleteService.current.getPlacePredictions(
                    request,
                    callback
                );
            }, 400),
        []
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current =
                new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <div className={classes["location-autocomplete"]}>
            <FormHelperText style={{ color: "#d32f2f" }}>
                {realProps.error}
            </FormHelperText>
            <Autocomplete
                getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                noOptionsText={realProps.noLocation}
                disabled={realProps.disabled}
                onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                    realProps.changeHandler({
                        description: newValue?.description,
                        place_id: newValue?.place_id,
                    });
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField {...params} label={realProps.label} fullWidth />
                )}
                renderOption={(props, option) => {
                    const matches =
                        option.structured_formatting
                            ?.main_text_matched_substrings || [];

                    const parts = parse(
                        option.structured_formatting?.main_text,
                        matches.map((match) => [
                            match.offset,
                            match.offset + match.length,
                        ])
                    );

                    return (
                        <li {...props} key={props.id}>
                            <Grid container alignItems='center'>
                                <Grid item sx={{ display: "flex", width: 44 }}>
                                    <LocationOnIcon
                                        sx={{ color: "text.secondary" }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        width: "calc(100% - 44px)",
                                        wordWrap: "break-word",
                                    }}
                                >
                                    {parts.map((part, index) => (
                                        <Box
                                            key={index}
                                            component='span'
                                            sx={{
                                                fontWeight: part.highlight
                                                    ? "bold"
                                                    : "regular",
                                            }}
                                        >
                                            {part.text}
                                        </Box>
                                    ))}
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        {
                                            option.structured_formatting
                                                ?.secondary_text
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
        </div>
    );
};

export default LocationAutocomplete;
