import classes from "./Pachete.module.scss";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../../store/modal-slice";
import { useAuth, getUserProfile } from "../../../../firebase-auth";
import Spinner from "../../../../Components/UI/Spinner/Spinner";
import AlertMessage from "../../../../Components/UI/AlertMessage/AlertMessage";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import TextInput from "../../../../Components/UI/Form/TextInput";
import { checkIfVoucherExist, saveOrUpdateInvitationData } from "../../../../firebase-invitatii";
import { errorHandler } from "../../../../Helpers/Helpers";
import Thanks from "../Thanks/Thanks";
import Cookies from "js-cookie";

const Pachete = (props) => {
    const { type, newInv, data, iid, isPending } = props;
    const [value, setValue] = useState("voucher");
    const [voucher, setVoucher] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [alertError, setAlertError] = useState(false);
    const [alert, setAlert] = useState({});

    const dispatch = useDispatch();
    const userData = useAuth();
    const cookiesArray = Object.keys(data);
    const checkedUrl = newInv ? JSON.parse(Cookies.get('checkedUrl')) || '' : data.url;

    const onVoucherChange = (voucher_code) => {
        setValue("voucher");
        setVoucher(voucher_code);
    };

    const onInputChange = (event) => {
        setValue(event.target.value);
        setVoucher("");
        setError("");
    };

    const openModalThanks = (invites) => {
        dispatch(modalActions.close());
        // Make sure we are deleting all unnecessary cookies
        Cookies.remove("checkout_step");
        // Cookies.remove("checkedUrl");
        Cookies.remove(cookiesArray['0']);
        Cookies.remove(cookiesArray['1']);
        Cookies.remove(cookiesArray['2']);
        dispatch(
            modalActions.modal({
                showModal: true,
                modalTitle: "Suntem gata",
                closeDisabled: false,
                modalContent: <Thanks invites={invites} />,
            })
        );
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSaving(true);
        const profile = await getUserProfile(userData);
        const invType = `${type}s1`;
        const voucherExist = await checkIfVoucherExist(voucher, profile.uid, newInv ? data[invType].iid : iid);
        // If the voucher exists
        voucherExist?.id && setError('');
        // If the voucher does not exist
        !voucherExist?.id && setError('Codul de voucher introdus nu este valid!');
        // If the voucher was already used
        voucherExist?.used && setError('Codul de voucher introdus a fost folosit deja! Încercați un alt cod.');
        setIsSaving(false);
        if (voucherExist?.id && voucherExist?.used === false && newInv) {
            setIsSaving(true);
            await saveOrUpdateInvitationData(profile.uid, type, checkedUrl, data, false)
                .then(() => {
                    setSuccess(true);
                    setIsSaving(false);
                    setAlert({
                        severity: "success",
                        title: "Modificări salvate",
                        description: "Modificările au fost salvate. Vă redirecționăm la pasul următor...",
                    });
                    openModalThanks(voucherExist?.invites);
                })
                .catch((error) => {
                    setAlertError(true);
                    setIsSaving(false);
                    setAlert(errorHandler(error));
                });
        }
        if (voucherExist?.id && voucherExist?.used === false && !newInv) {
            setSuccess(true);
            setIsSaving(false);
            setAlert({
                severity: "success",
                title: "Modificări salvate",
                description: "Felicitări! Răspunsurile activate vor fi adăugate în curând invitației.",
            });
        }
    };

    return (
        <div className={classes["plans-wrapper"]}>
            {isSaving && <Spinner text='Validare voucher...' />}
            {alertError && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {success && (
                <AlertMessage
                    severity={alert.severity}
                    title={alert.title}
                    description={alert.description}
                    refresh={alert.refresh}
                />
            )}
            {!isSaving && !success && !alertError && (
                <form onSubmit={handleSubmit}>
                    <FormControl>
                        {isPending && (
                            <p className={classes.intro}>
                                <strong>
                                    Se pare că nu ai finalizat invitația anterioară. Acesta este ultimul pas.
                                </strong>
                            </p>
                        )}
                        <p className={classes.intro}>
                            Alege pachetul dorit sau introdu codul de voucher
                            primit:
                        </p>
                        <RadioGroup
                            className={classes["radio-group"]}
                            value={value}
                            onChange={onInputChange}
                        >
                            <FormControlLabel
                                disabled
                                value='inv50'
                                control={<Radio />}
                                label={
                                    <span>
                                        Până la 50 răspunsuri la invitație (
                                        <del>100</del> 50 lei)
                                    </span>
                                }
                            />
                            <FormControlLabel
                                disabled
                                value='inv100'
                                control={<Radio />}
                                label={
                                    <span>
                                        Până la 100 răspunsuri la invitație (
                                        <del>180</del> 90 lei)
                                    </span>
                                }
                            />
                            <FormControlLabel
                                disabled
                                value='inv150'
                                control={<Radio />}
                                label={
                                    <span>
                                        Până la 150 răspunsuri la invitație (
                                        <del>260</del> 130 lei)
                                    </span>
                                }
                            />
                            <FormControlLabel
                                disabled
                                value='inv200'
                                control={<Radio />}
                                label={
                                    <span>
                                        Până la 200 răspunsuri la invitație (
                                        <del>340</del> 170 lei)
                                    </span>
                                }
                            />
                            <FormControlLabel
                                disabled
                                value='inv300'
                                control={<Radio />}
                                label={
                                    <span>
                                        Până la 300 răspunsuri la invitație (
                                        <del>500</del> 250 lei)
                                    </span>
                                }
                            />
                            <FormControlLabel
                                disabled
                                value='inv400'
                                control={<Radio />}
                                label={
                                    <span>
                                        Până la 400 răspunsuri la invitație (
                                        <del>660</del> 330 lei)
                                    </span>
                                }
                            />
                            <FormControlLabel
                                id={classes["voucher-input"]}
                                value='voucher'
                                control={<Radio />}
                                label={
                                    <TextInput
                                        label='Cod voucher'
                                        value={voucher}
                                        changeHandler={onVoucherChange}
                                        error={error}
                                        autoComplete='off'
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                    <div className={classes.actions}>
                        <Button type='submit' variant='contained'>
                            Plătește / validează voucher
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Pachete;
